import DoneIcon from '@mui/icons-material/Done';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, FormControl, FormLabel, Stack, Switch, TextField, Typography, useTheme } from '@mui/material';
import { CharactersRemaining } from 'components/CharactersRemaining';
import { HighlightAvatar } from 'components/HighlightAvatar';
import { HighlightAvatarSelector } from 'components/HighlightAvatarSelector';
import { Pill } from 'components/Pill';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';
import { HiddenSectionHelpPill } from '../HiddenSectionHelpPill';

const HIGHLIGHT_CHAR_LIMIT = 100;

export interface HighlightsSectionProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const SchoolHighlights = ({ control, errors }: HighlightsSectionProps) => {
  const highlightText1 = useWatch({ control, name: 'highlightText1' });
  const highlightText2 = useWatch({ control, name: 'highlightText2' });
  const highlightText3 = useWatch({ control, name: 'highlightText3' });
  const isComplete = highlightText1 && highlightText2 && highlightText3;

  const theme = useTheme();

  return (
    <section id="school-highlights-section">
      <Stack direction="row" alignItems="center">
        <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
          School highlights
        </Typography>
        <Controller
          name="showHighlightsSection"
          control={control}
          render={({ field: { value, onChange } }) => (
            <>
              <Switch checked={value} onChange={(event, value) => onChange(value)} />
              {!value && <HiddenSectionHelpPill />}
            </>
          )}
        />
        <Box flex={1} />
        <Pill
          label={isComplete ? 'Complete' : 'Incomplete'}
          color={isComplete ? 'success' : 'error'}
          icon={isComplete ? <DoneIcon color="success" /> : <WarningAmberIcon color="error" />}
        />
      </Stack>
      <Typography>
        Select three icons that represent your school&rsquo;s best features and add a caption summarizing the details of each feature. These features
        distinguish your school from others, and capture what it is your school succeeds at exceedingly well. E.g. &ldquo;World class music program&rdquo;
      </Typography>
      <Stack direction="row" pt={3}>
        {([1, 2, 3] as [1, 2, 3]).map((n) => (
          <Stack key={n} direction="column" alignItems="center" p={2} width="100%">
            <Controller
              name={`highlightIcon${n}`}
              control={control}
              render={({ field: { value, onChange } }) => {
                const labelId = `highlightIcon${n}-label`;
                return (
                  <>
                    <HighlightAvatar icon={value} iconScale={5} width={200} height={200} color={theme.palette.primary.main} />
                    <FormControl fullWidth required sx={{ my: 2 }}>
                      <FormLabel id={labelId}>Highlight {n} icon</FormLabel>
                      <HighlightAvatarSelector value={value} onChange={onChange} labelId={labelId} />
                    </FormControl>
                  </>
                );
              }}
            />
            <Controller
              name={`highlightText${n}`}
              control={control}
              rules={{
                required: `Highlight text is required`,
                maxLength: { value: 100, message: 'Highlight text is too long' },
              }}
              render={({ field: { value, onChange } }) => {
                const labelId = `highlightText${n}-label`;
                const error = errors[`highlightText${n}`];
                return (
                  <FormControl fullWidth required>
                    <FormLabel id={labelId} sx={{ color: error ? theme.palette.error.main : undefined }}>
                      Highlight {n} text
                    </FormLabel>
                    <TextField size="small" value={value} onChange={onChange} fullWidth aria-labelledby={labelId} error={Boolean(error)} />
                    <CharactersRemaining limit={HIGHLIGHT_CHAR_LIMIT} value={value} />
                    {error ? <Typography color="error">{error?.message}</Typography> : <br />}
                  </FormControl>
                );
              }}
            />
          </Stack>
        ))}
      </Stack>
    </section>
  );
};
