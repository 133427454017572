import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { HomestayProvider } from 'types/homestay-provider.enum';

interface HomestayProviderFilterProps {
  value: HomestayProvider;
  onChange: (newHomestayProviderStatus: HomestayProvider) => void;
  onDiscard: () => void;
}

export const HomestayProviderFilter = ({ value, onChange, onDiscard }: HomestayProviderFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Homestay Provider
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="homestay-provider-filter-select-label">Select status</InputLabel>
        <Select
          labelId="homestay-provider-filter-select-label"
          id="homestay-provider-filter-select"
          label="Select status"
          value={value}
          onChange={(event) => onChange(event.target.value as HomestayProvider)}
        >
          <MenuItem value={HomestayProvider.Any}>Any</MenuItem>
          <MenuItem value={HomestayProvider.School}>School</MenuItem>
          <MenuItem value={HomestayProvider.Educatius}>Educatius</MenuItem>
          <MenuItem value={HomestayProvider.AustralianHomestayNetwork}>Australian Homestay Network</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
