import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';

import { FilterFlag } from '../filter-flag.type';

interface EsolSupportFilterProps {
  value: FilterFlag;
  onChange: (hasEsolSupport: FilterFlag) => void;
  onDiscard: () => void;
}

export const EsolSupportFilter = ({ onDiscard }: EsolSupportFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          ESOL Support
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckIcon />
        <Typography pt={'5px'}>English as second / other spoken language support</Typography>
      </Stack>
    </Card>
  );
};
