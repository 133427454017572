import { Buffer } from 'buffer';
import mapboxgl from 'mapbox-gl';
import { createRoot } from 'react-dom/client';
import { initLogRocket } from 'utils/logRocket';
import { initSentry } from 'utils/sentry';

import App from './app/App';
import { getConfig } from './utils/config';

// TODO: Check whether we still need this after migrating to Auth0
// CRA v5+ no longer bundles node polyfills
// @ts-ignore
window.Buffer = Buffer;

const { environment, mapboxToken } = getConfig();

mapboxgl.accessToken = mapboxToken;

if (environment !== 'local') {
  initLogRocket();
  initSentry();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
