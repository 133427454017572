import { FilterTypes } from './filter-types.enum';

export const getInitialFiltersFromParams = (values: any) => {
  const initialFilters: FilterTypes[] = [];

  if (values.hasOwnProperty('country')) {
    initialFilters.push(FilterTypes.Location);
  }
  if (values.hasOwnProperty('coedStatus')) {
    initialFilters.push(FilterTypes.CoedStatus);
  }
  if (values.hasOwnProperty('customAttribute')) {
    initialFilters.push(FilterTypes.CustomAttribute);
  }
  if (values.hasOwnProperty('pricingProgramPeriod') || values.hasOwnProperty('pricingMinUsd') || values.hasOwnProperty('pricingMaxUsd')) {
    initialFilters.push(FilterTypes.ProgramPricing);
  }
  if (values.hasOwnProperty('programAvailability')) {
    initialFilters.push(FilterTypes.ProgramAvailability);
  }
  // VDET does not have a country set and does not use the Location filter
  if (values.hasOwnProperty('city') && !values.hasOwnProperty('country')) {
    initialFilters.push(FilterTypes.City);
  }
  if (values.hasOwnProperty('isPrivateSchool')) {
    initialFilters.push(FilterTypes.PublicOrPrivate);
  }
  if (values.hasOwnProperty('hasEsolSupport')) {
    initialFilters.push(FilterTypes.EsolSupport);
  }
  if (values.hasOwnProperty('hasUsDiploma')) {
    initialFilters.push(FilterTypes.UsDiploma);
  }
  if (values.hasOwnProperty('acceptsAge')) {
    initialFilters.push(FilterTypes.AcceptsAge);
  }
  if (values.hasOwnProperty('acceptsGrade')) {
    initialFilters.push(FilterTypes.AcceptsGrade);
  }
  if (values.hasOwnProperty('dayOrBoarding')) {
    initialFilters.push(FilterTypes.DayOrBoarding);
  }
  if (values.hasOwnProperty('subjects')) {
    initialFilters.push(FilterTypes.Subjects);
  }
  if (values.hasOwnProperty('sports')) {
    initialFilters.push(FilterTypes.Sports);
  }
  if (values.hasOwnProperty('homestayProvider')) {
    initialFilters.push(FilterTypes.HomestayProvider);
  }
  return initialFilters;
};
