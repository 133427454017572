import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, Button, Divider, FormControl, FormLabel, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ApplicationWindowStatusPill } from 'components/ApplicationWindowStatusPill';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, useFieldArray, useWatch } from 'react-hook-form';
import { stripNonNumerical } from 'utils/stripNonNumerical';

import { EditableProfileFields } from '../get-editable-profile-fields';

export interface StudyProgramPeriodSectionProps {
  studyProgramIndex: number;
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const StudyProgramPeriodSection = ({ studyProgramIndex, control, errors }: StudyProgramPeriodSectionProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `studyPrograms.${studyProgramIndex}.studyProgramPeriods`,
  });
  const watchFieldArray = useWatch({ control, name: `studyPrograms.${studyProgramIndex}.studyProgramPeriods` });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const locale = 'en-US';
  const currency = useWatch({ control, name: 'currency' });
  const [currencySymbol, totalCostFormatter] = useMemo(
    () => [
      (0).toLocaleString(locale, { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim(),
      new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
      }),
    ],
    [currency],
  );

  const periodFormattedTotalCosts = watchFieldArray.map((period) => {
    const totalCost =
      Number(period.basePrice || 0) +
      Number(period.insuranceFee || 0) +
      Number(period.applicationFee || 0) +
      Number(period.accommodationFee || 0) +
      Number(period.otherFees || 0) +
      Number(period.commission || 0);
    return `${totalCostFormatter.format(parseFloat(totalCost.toString(10)))} ${currency}`;
  });

  const addPeriod = () => {
    // Autocomplete from last period if exists
    const lastPeriod = watchFieldArray.length > 0 ? watchFieldArray[watchFieldArray.length - 1] : null;
    append({
      costType: 'net',
      intakeStartAt: '',
      intakeApplicationsStartAt: '',
      intakeApplicationsEndAt: '',
      useCustomFees: true,
      basePrice: '',
      insuranceFee: lastPeriod?.insuranceFee || '',
      applicationFee: lastPeriod?.applicationFee || '',
      accommodationFee: lastPeriod?.accommodationFee || '',
      otherFees: lastPeriod?.otherFees || '',
    });
  };

  return (
    <>
      {controlledFields.map((field, index) => (
        <Box key={index}>
          <Divider sx={{ my: 3 }}>{watchFieldArray[index]?.intakeStartAt && format(new Date(watchFieldArray[index]?.intakeStartAt), 'MMMM yyyy')}</Divider>
          <Stack>
            <Stack direction="row" alignItems="center">
              <Controller
                name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.intakeStartAt`}
                control={control}
                rules={{
                  required: 'Study start date required',
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl size="small" sx={{ width: 750, mr: 3 }}>
                    <FormLabel required id={`intake-start-input-label`}>
                      Study period start
                    </FormLabel>
                    <DatePicker value={new Date(value)} onChange={(newValue) => onChange(newValue)} />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeStartAt ? (
                      <Typography color="error">{errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeStartAt?.message}</Typography>
                    ) : (
                      <br />
                    )}
                  </FormControl>
                )}
              />
              {/* TODO: MUI date range components require a commerical license to use @mui/x-date-pickers-pro. */}
              {/* intakeApplicationsStartAt and intakeApplicationsEndAt could be merged into a single tuple field in the form hook field */}
              <Controller
                name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.intakeApplicationsStartAt`}
                control={control}
                rules={{
                  required: 'Open date required',
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl size="small" sx={{ width: 750, mr: 3 }}>
                    <FormLabel required id={`applications-window-start-input-label`}>
                      Applications open
                    </FormLabel>
                    <DatePicker value={new Date(value)} onChange={(newValue) => onChange(newValue)} />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeApplicationsStartAt ? (
                      <Typography color="error">
                        {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeApplicationsStartAt?.message}
                      </Typography>
                    ) : (
                      <br />
                    )}
                  </FormControl>
                )}
              />
              <Controller
                name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.intakeApplicationsEndAt`}
                control={control}
                rules={{
                  required: 'Close date required',
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl size="small" sx={{ width: 750 }}>
                    <FormLabel required id={`applications-window-end-input-label`}>
                      Applications close
                    </FormLabel>
                    <DatePicker value={new Date(value)} onChange={(newValue) => onChange(newValue)} />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeApplicationsEndAt ? (
                      <Typography color="error">
                        {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.intakeApplicationsEndAt?.message}
                      </Typography>
                    ) : (
                      <br />
                    )}
                  </FormControl>
                )}
              />
              <Box mx={3}>
                <ApplicationWindowStatusPill
                  windowStart={watchFieldArray[index]?.intakeApplicationsStartAt || ''}
                  windowEnd={watchFieldArray[index]?.intakeApplicationsEndAt || ''}
                />
              </Box>
              <Controller
                name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.basePrice`}
                control={control}
                rules={{
                  required: 'Tuition fee is required',
                  min: { value: 0, message: 'Cannot be less than 0' },
                  max: { value: 9999999999, message: 'Cannot be higher than 9999999999' },
                }}
                render={({ field: { value, onChange } }) => (
                  <FormControl fullWidth size="small">
                    <FormLabel required id={`tuition-fee-input-label`}>
                      Tuition fee
                    </FormLabel>
                    <TextField
                      fullWidth
                      aria-labelledby={`tuition-fee-input-label`}
                      id={`tuition-fee-input`}
                      value={value ?? ''}
                      onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                      error={Boolean(errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.basePrice)}
                      InputProps={{
                        startAdornment: <Typography mr={1}>{currencySymbol}</Typography>,
                      }}
                    />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.basePrice ? (
                      <Typography color="error">{errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.basePrice?.message}</Typography>
                    ) : (
                      <br />
                    )}
                  </FormControl>
                )}
              />
            </Stack>

            <Controller
              name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.applicationFee`}
              control={control}
              rules={{
                min: { value: 0, message: 'Cannot be less than 0' },
                max: { value: 9999999999, message: 'Cannot be higher than 9999999999' },
              }}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth size="small">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <Box flex={1} />
                    <Typography id={`application-fee-input-label`} fontWeight={500} sx={{ mr: 2 }}>
                      Application fee:
                    </Typography>
                    <TextField
                      fullWidth
                      aria-labelledby={`application-fee-input-label`}
                      id={`application-fee-input`}
                      value={value ?? ''}
                      onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                      size="small"
                      error={Boolean(errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.applicationFee)}
                      InputProps={{
                        startAdornment: <Typography mr={1}>{currencySymbol}</Typography>,
                        sx: {
                          '& input': {
                            textAlign: 'right',
                          },
                        },
                      }}
                      sx={{ width: 191 }}
                    />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.applicationFee ? (
                      <Typography color="error">{errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.applicationFee?.message}</Typography>
                    ) : (
                      <br />
                    )}
                  </Stack>
                </FormControl>
              )}
            />

            <Controller
              name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.insuranceFee`}
              control={control}
              rules={{
                min: { value: 0, message: 'Cannot be less than 0' },
                max: { value: 9999999999, message: 'Cannot be higher than 9999999999' },
              }}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth size="small">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <Box flex={1} />
                    <Typography id={`insurance-fee-input-label`} fontWeight={500} sx={{ mr: 2 }}>
                      Insurance fee:
                    </Typography>
                    <TextField
                      fullWidth
                      aria-labelledby={`insurance-fee-input-label`}
                      id={`insurance-fee-input`}
                      value={value ?? ''}
                      onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                      size="small"
                      error={Boolean(errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.insuranceFee)}
                      InputProps={{
                        startAdornment: <Typography mr={1}>{currencySymbol}</Typography>,
                        sx: {
                          '& input': {
                            textAlign: 'right',
                          },
                        },
                      }}
                      sx={{ width: 191 }}
                    />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.insuranceFee ? (
                      <Typography color="error">{errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.insuranceFee?.message}</Typography>
                    ) : (
                      <br />
                    )}
                  </Stack>
                </FormControl>
              )}
            />

            <Controller
              name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.accommodationFee`}
              control={control}
              rules={{
                min: { value: 0, message: 'Cannot be less than 0' },
                max: { value: 9999999999, message: 'Cannot be higher than 9999999999' },
              }}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth size="small">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <Box flex={1} />
                    <Typography id={`accommodation-fee-input-label`} fontWeight={500} sx={{ mr: 2 }}>
                      Accommodation fee:
                    </Typography>
                    <TextField
                      fullWidth
                      aria-labelledby={`accommodation-fee-input-label`}
                      id={`accommodation-fee-input`}
                      value={value ?? ''}
                      onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                      size="small"
                      error={Boolean(errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.accommodationFee)}
                      InputProps={{
                        startAdornment: <Typography mr={1}>{currencySymbol}</Typography>,
                        sx: {
                          '& input': {
                            textAlign: 'right',
                          },
                        },
                      }}
                      sx={{ width: 191 }}
                    />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.accommodationFee ? (
                      <Typography color="error">
                        {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.accommodationFee?.message}
                      </Typography>
                    ) : (
                      <br />
                    )}
                  </Stack>
                </FormControl>
              )}
            />

            <Controller
              name={`studyPrograms.${studyProgramIndex}.studyProgramPeriods.${index}.otherFees`}
              control={control}
              rules={{
                min: { value: 0, message: 'Cannot be less than 0' },
                max: { value: 9999999999, message: 'Cannot be higher than 9999999999' },
              }}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth size="small">
                  <Stack direction="row" alignItems="center" mb={1}>
                    <Box flex={1} />
                    <Typography id={`other-fees-input-label`} fontWeight={500} sx={{ mr: 2 }}>
                      Other fees:
                    </Typography>
                    <TextField
                      fullWidth
                      aria-labelledby={`other-fees-input-label`}
                      id={`other-fees-input`}
                      value={value ?? ''}
                      onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                      size="small"
                      error={Boolean(errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.otherFees)}
                      InputProps={{
                        startAdornment: <Typography mr={1}>{currencySymbol}</Typography>,
                        sx: {
                          '& input': {
                            textAlign: 'right',
                          },
                        },
                      }}
                      sx={{ width: 191 }}
                    />
                    {errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.otherFees ? (
                      <Typography color="error">{errors.studyPrograms?.[studyProgramIndex]?.studyProgramPeriods?.[index]?.otherFees?.message}</Typography>
                    ) : (
                      <br />
                    )}
                  </Stack>
                </FormControl>
              )}
            />

            <Stack direction="row">
              <Box flex={1} />
              <Typography fontWeight={600} mr={2} mb={3}>
                Total cost:
              </Typography>
              <Typography fontWeight={600} fontSize="18px" sx={{ width: 191 }} textAlign="right">
                {periodFormattedTotalCosts[index]}
              </Typography>
            </Stack>

            <Stack direction="row-reverse">
              <Button size="large" variant="outlined" onClick={() => remove(index)} color="error" endIcon={<DeleteOutlinedIcon />}>
                Remove study period
              </Button>
            </Stack>
          </Stack>
        </Box>
      ))}
      <Divider sx={{ my: 2 }} />
      <Button variant="outlined" color="info" onClick={addPeriod} endIcon={<AddIcon />}>
        Add study period
      </Button>
    </>
  );
};
