const fileSize = (bytes: number): string => {
  const kb = bytes / 1000 || 0;
  const mb = kb / 1000 || 0;
  const isMegaBytes = bytes > 1000000;
  const units = isMegaBytes ? 'MB' : 'KB';
  const size = Math.round(isMegaBytes ? mb : kb);

  return `${size} ${units}`;
};

export default fileSize;
