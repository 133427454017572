import { Box, Button, ImageList, ImageListItem, Paper, Typography, useTheme } from '@mui/material';
import { ImageWithSkeleton } from 'components/ImageWithSkeleton';
import { SlateRenderer } from 'components/SlateRenderer';
import { MouseEventHandler, useState } from 'react';
import ReactGA from 'react-ga4';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';
import { getOptimisedImageUrl, ImageEditOptions } from 'utils/getOptimisedImageUrl';

import { useProfile } from '../../ProfileContext';

export const AboutSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const [isAboutSectionCollapsed, setIsAboutSectionCollapsed] = useState<boolean>(true);
  const theme = useTheme();
  const color = theme.palette.primary.main;

  const description = profile.description?.length > 0 ? profile.description : [{ children: [{ text: '' }] }];

  const files = profile.files || [];
  const banner = files.find((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_BANNER);
  const photos = files.filter((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_PHOTOS);

  const onLinkClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    const anchor = event.target as HTMLAnchorElement;
    const href = anchor?.getAttribute('href') || undefined;
    ReactGA.event({ category: 'Institution Profile Content Link', label: href, action: 'Clicked' });
  };

  const imageEditOptions: ImageEditOptions = {
    resize: { width: 1200, fit: 'cover' },
  };

  return (
    <Paper style={{ overflowY: 'hidden', position: 'relative', marginBottom: '10px' }}>
      {banner ? (
        <ImageList cols={1} rowHeight={300} sx={{ mt: 0 }} component={Box}>
          <ImageListItem>
            <ImageWithSkeleton
              src={getOptimisedImageUrl(banner.fileUrl!, imageEditOptions)}
              alt="banner"
              style={{ objectFit: 'cover', height: 'inherit' }}
              width="100%"
              height="100%"
            />
          </ImageListItem>
        </ImageList>
      ) : photos.length >= 5 ? (
        <ImageList variant="quilted" cols={4} rowHeight={150} sx={{ mt: 0 }} component={Box}>
          {photos.slice(0, 5).map((p, idx: number) => (
            <ImageListItem key={p.id} cols={idx === 0 ? 2 : 1} rows={idx === 0 ? 2 : 1}>
              <ImageWithSkeleton
                src={getOptimisedImageUrl(p.fileUrl!, imageEditOptions)}
                alt="banner"
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <></>
      )}

      <div style={{ padding: '20px' }}>
        <Typography variant="h5" color={color}>
          About
        </Typography>
        <div style={{ overflowY: 'hidden', maxHeight: isAboutSectionCollapsed ? '140px' : 'inherit', marginBottom: '10px' }}>
          <SlateRenderer rootSlateNode={description} onLinkClick={onLinkClick} />
        </div>
        {isAboutSectionCollapsed && <Button onClick={() => setIsAboutSectionCollapsed(false)}>See more</Button>}
        {!isAboutSectionCollapsed && <Button onClick={() => setIsAboutSectionCollapsed(true)}>See less</Button>}
      </div>
    </Paper>
  );
};
