import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { HomestayProvider } from 'types/homestay-provider.enum';
import { ProfileData } from 'types/profile.interface';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';
import { useDebounce } from 'use-debounce';

import { FilterBoolean } from './filtering/filter-boolean.type';
import { FilterFlag } from './filtering/filter-flag.type';
import { FilterParams } from './filtering/filter-params.interface';

type FiltersState = {
  filters: FilterParams;
  search: string;
  pagination: {
    page: number;
    pageSize: number;
    order: string;
    orderDirection: 'ASC' | 'DESC';
  };
};

type FiltersContextData = {
  state: FiltersState;
  updateFilters: (filters: FilterParams) => void;
  updateSearch: (search: string) => void;
  updatePage: (page: number) => void;
  updatePagination: (pagination: { pageSize: number; order: string; orderDirection: 'ASC' | 'DESC' }) => void;
};

const FiltersContext = createContext<FiltersContextData | undefined>(undefined);

const getFiltersFromSearchParams = (searchParams: URLSearchParams): FilterParams => {
  const filterParams: FilterParams = {};

  if (searchParams.get('coedStatus')) {
    filterParams.coedStatus = searchParams.get('coedStatus') as CoedStatuses;
  }

  if (searchParams.get('country')) {
    filterParams.country = searchParams.get('country') as ProfileData['country'];
  }

  if (searchParams.get('state')) {
    filterParams.state = searchParams.get('state') as ProfileData['state'];
  }

  if (searchParams.get('city')) {
    filterParams.city = searchParams.get('city') as ProfileData['city'];
  }

  if (searchParams.get('customAttribute')) {
    filterParams.customAttribute = searchParams.get('customAttribute') as ProfileData['customAttribute'];
  }

  if (searchParams.get('pricingProgramPeriod')) {
    filterParams.pricingProgramPeriod = searchParams.get('pricingProgramPeriod') as StudyProgramPeriodType;
  }

  if (searchParams.get('programAvailability')) {
    filterParams.programAvailability = searchParams.get('programAvailability') as FilterFlag;
  }

  if (searchParams.get('pricingMinUsd')) {
    filterParams.pricingMinUsd = searchParams.get('pricingMinUsd') as StudyProgramPeriod['basePriceUsd'];
  }

  if (searchParams.get('pricingMaxUsd')) {
    filterParams.pricingMaxUsd = searchParams.get('pricingMaxUsd') as StudyProgramPeriod['basePriceUsd'];
  }

  if (searchParams.get('isPrivateSchool')) {
    filterParams.isPrivateSchool = searchParams.get('isPrivateSchool') as FilterBoolean;
  }

  if (searchParams.get('hasEsolSupport')) {
    filterParams.hasEsolSupport = searchParams.get('hasEsolSupport') as FilterFlag;
  }

  if (searchParams.get('hasUsDiploma')) {
    filterParams.hasUsDiploma = searchParams.get('hasUsDiploma') as FilterFlag;
  }

  if (searchParams.get('acceptsAge')) {
    filterParams.acceptsAge = parseInt(searchParams.get('acceptsAge')!);
  }

  if (searchParams.get('dayOrBoarding')) {
    filterParams.dayOrBoarding = searchParams.get('dayOrBoarding') as ProfileData['dayOrBoarding'];
  }

  if (searchParams.get('acceptsGrade')) {
    filterParams.acceptsGrade = parseInt(searchParams.get('acceptsGrade')!);
  }

  if (searchParams.getAll('subjects').length > 0) {
    filterParams.subjects = searchParams.getAll('subjects');
  }

  if (searchParams.getAll('sports').length > 0) {
    filterParams.sports = searchParams.getAll('sports');
  }

  if (searchParams.get('homestayProvider')) {
    filterParams.homestayProvider = searchParams.get('homestayProvider') as HomestayProvider;
  }

  return filterParams;
};

interface FiltersProviderProps {
  children: ReactNode;
}

export const FiltersProvider = ({ children }: FiltersProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filterParams, setFilterParams] = useState<FilterParams>(getFiltersFromSearchParams(searchParams));
  const [sortBy, setSortBy] = useState(searchParams.get('sortBy') || 'name');
  const [sortOrder, setSortOrder] = useState<'ASC' | 'DESC'>((searchParams.get('sortOrder') as 'ASC' | 'DESC') || 'ASC');
  const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get('page') || '1'));
  const [profilePageSize, setProfilePageSize] = useState(parseInt(searchParams.get('pageSize') || '12'));
  const [searchTerm, setSearchTerm] = useState<string>(searchParams.get('search') || '');

  const [debouncedPricingMinUsd] = useDebounce<string>(filterParams.pricingMinUsd!, 400);
  const [debouncedPricingMaxUsd] = useDebounce<string>(filterParams.pricingMaxUsd!, 400);

  useEffect(() => {
    // Don't show empty params, e.g. 'country=&city=' vs ''
    const newSearchParams = {} as any;
    if (filterParams.coedStatus) newSearchParams['coedStatus'] = filterParams.coedStatus;
    if (filterParams.country) newSearchParams['country'] = filterParams.country;
    if (filterParams.state) newSearchParams['state'] = filterParams.state;
    if (filterParams.city) newSearchParams['city'] = filterParams.city;
    if (filterParams.customAttribute) newSearchParams['customAttribute'] = filterParams.customAttribute;
    if (`${currentPage}`) newSearchParams['page'] = `${currentPage}`;
    if (`${profilePageSize}`) newSearchParams['pageSize'] = `${profilePageSize}`;
    if (searchTerm) newSearchParams['search'] = searchTerm;
    if (sortBy) newSearchParams['sortBy'] = sortBy;
    if (sortOrder) newSearchParams['sortOrder'] = sortOrder;
    if (filterParams.programAvailability) newSearchParams['programAvailability'] = filterParams.programAvailability;
    if (filterParams.pricingProgramPeriod) newSearchParams['pricingProgramPeriod'] = filterParams.pricingProgramPeriod;
    if (debouncedPricingMinUsd) newSearchParams['pricingMinUsd'] = debouncedPricingMinUsd;
    if (debouncedPricingMaxUsd) newSearchParams['pricingMaxUsd'] = debouncedPricingMaxUsd;
    if (filterParams.isPrivateSchool) newSearchParams['isPrivateSchool'] = filterParams.isPrivateSchool;
    if (filterParams.hasEsolSupport) newSearchParams['hasEsolSupport'] = filterParams.hasEsolSupport;
    if (filterParams.hasUsDiploma) newSearchParams['hasUsDiploma'] = filterParams.hasUsDiploma;
    if (filterParams.acceptsAge) newSearchParams['acceptsAge'] = filterParams.acceptsAge;
    if (filterParams.dayOrBoarding) newSearchParams['dayOrBoarding'] = filterParams.dayOrBoarding;
    if (filterParams.acceptsGrade) newSearchParams['acceptsGrade'] = filterParams.acceptsGrade;
    if ((filterParams.subjects?.length || 0) > 0) newSearchParams['subjects'] = filterParams.subjects;
    if ((filterParams.sports?.length || 0) > 0) newSearchParams['sports'] = filterParams.sports;
    if (filterParams.homestayProvider) newSearchParams['homestayProvider'] = filterParams.homestayProvider;
    setSearchParams(newSearchParams);
  }, [filterParams, currentPage, searchTerm, sortBy, sortOrder, setSearchParams, debouncedPricingMinUsd, debouncedPricingMaxUsd, profilePageSize]);

  const updateFilters = useCallback((filters: FilterParams) => {
    setFilterParams(filters);
    setCurrentPage(1);
  }, []);

  const updateSearch = useCallback((search: string) => {
    setSearchTerm(search);
    setCurrentPage(1);
  }, []);

  const updatePagination = useCallback(({ pageSize, order, orderDirection }: { pageSize: number; order: string; orderDirection: 'ASC' | 'DESC' }) => {
    setSortBy(order);
    setSortOrder(orderDirection);
    setProfilePageSize(pageSize);
    setCurrentPage(1);
  }, []);

  return (
    <FiltersContext.Provider
      value={{
        state: {
          filters: filterParams,
          search: searchTerm,
          pagination: { page: currentPage, pageSize: profilePageSize, order: sortBy, orderDirection: sortOrder },
        },
        updateFilters,
        updateSearch,
        updatePage: useCallback((page: number) => setCurrentPage(page), []),
        updatePagination,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => {
  const context = useContext(FiltersContext);

  if (context === undefined) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  return context;
};
