import AddIcon from '@mui/icons-material/Add';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { CharactersRemaining } from 'components/CharactersRemaining';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, useFieldArray, useFormState, useWatch } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';
import { HiddenSectionHelpPill } from '../HiddenSectionHelpPill';

const QUOTE_CHAR_LIMIT = 500;
const NAME_CHAR_LIMIT = 75;

export interface TestimonialsSectionProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const TestimonialsSection = ({ errors, control }: TestimonialsSectionProps) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'testimonials',
  });
  const watchFieldArray = useWatch({ control, name: 'testimonials' });
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });
  const { isSubmitting } = useFormState({ control });
  const [expanded, setExpanded] = useState<boolean[]>(Array(controlledFields.length).fill(false));

  // Errors on save expand errored testimonials
  useEffect(() => {
    if (isSubmitting) {
      setExpanded((exp) => {
        if (errors && errors.testimonials && Array.isArray(errors.testimonials)) {
          const temp = [...exp];
          errors.testimonials.forEach((e, index) => {
            if (e && (e.name || e.quote)) {
              temp[index] = true;
            }
          });
          return temp;
        }
        return exp;
      });
    }
  }, [errors, setExpanded, isSubmitting]);

  const toggleExpand = (index: number, state: boolean) => {
    const temp = [...expanded];
    temp[index] = state;
    setExpanded(temp);
  };

  const addTestimonial = () => {
    append({ name: '', quote: '', type: 'Student' });
    const temp = [...expanded];
    temp.push(true);
    setExpanded(temp);
  };

  const removeTestimonial = (index: number) => {
    remove(index);
    const temp = [...expanded];
    temp.splice(index, 1);
    setExpanded(temp);
  };

  return (
    <section id="testimonials-section">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
            Testimonials
          </Typography>
          <Controller
            name="showTestimonialsSection"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Switch checked={value} onChange={(event, value) => onChange(value)} />
                {!value && <HiddenSectionHelpPill />}
              </>
            )}
          />
        </Stack>
      </Stack>
      <Box sx={{ mt: 3 }}>
        {controlledFields.map((field, index) => (
          <Box key={index}>
            {/* outer Box needed to avoid Accordion group styling */}
            <Accordion
              key={index}
              expanded={expanded[index]}
              onChange={(_, exp) => toggleExpand(index, exp)}
              sx={{ mb: 2, [`&.${accordionClasses.expanded}:last-of-type`]: { mb: 2 } }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Testimonial {index + 1}
                  {watchFieldArray[index]?.name && ` by ${watchFieldArray[index]?.name} (${watchFieldArray[index]?.type})`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Stack direction="row" gap={3}>
                  <Controller
                    name={`testimonials.${index}.quote`}
                    control={control}
                    rules={{
                      required: 'Testimonial quote is required',
                      maxLength: { value: QUOTE_CHAR_LIMIT, message: 'Testimonial quote is too long' },
                    }}
                    render={({ field: { value, onChange } }) => (
                      <Stack flex={5}>
                        <FormControl fullWidth required>
                          <FormLabel id={`testimonial-${index}-quote-input-label`}>Testimonial</FormLabel>
                          <TextField
                            size="small"
                            multiline
                            rows={5}
                            aria-labelledby={`testimonial-${index}-quote-input-label`}
                            value={value}
                            onChange={onChange}
                            error={Boolean(errors.testimonials?.[index]?.quote)}
                          />
                        </FormControl>
                        <CharactersRemaining limit={QUOTE_CHAR_LIMIT} value={value} />
                        {errors.testimonials?.[index]?.quote ? <Typography color="error">{errors.testimonials?.[index]?.quote?.message}</Typography> : <br />}
                      </Stack>
                    )}
                  />
                  <Stack flex={3} alignItems="flex-end">
                    <Controller
                      name={`testimonials.${index}.name`}
                      control={control}
                      rules={{
                        required: 'Testimonial name is required',
                        maxLength: { value: NAME_CHAR_LIMIT, message: 'Testimonial name is too long' },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <Box sx={{ mb: 2, width: '100%' }}>
                          <FormControl fullWidth required>
                            <FormLabel id={`testimonial-${index}-name-input-label`}>Testimonial by</FormLabel>
                            <TextField
                              size="small"
                              aria-labelledby={`testimonial-${index}-name-input-label`}
                              value={value}
                              onChange={onChange}
                              error={Boolean(errors.testimonials?.[index]?.name)}
                            />
                          </FormControl>
                          <CharactersRemaining limit={NAME_CHAR_LIMIT} value={value} />
                          {errors.testimonials?.[index]?.name ? <Typography color="error">{errors.testimonials?.[index]?.name?.message}</Typography> : <br />}
                        </Box>
                      )}
                    />
                    <Controller
                      name={`testimonials.${index}.type`}
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <FormControl fullWidth required>
                          <FormLabel id={`testimonial-${index}-type-input-label`}>Role</FormLabel>
                          <Select
                            aria-labelledby={`testimonial-${index}-type-input-label`}
                            value={value || 'Student'}
                            size="small"
                            onChange={onChange}
                            sx={{ mb: 2 }}
                          >
                            <MenuItem value={'Student'}>Student</MenuItem>
                            <MenuItem value={'Parent'}>Parent</MenuItem>
                            <MenuItem value={'Staff'}>Staff</MenuItem>
                            <MenuItem value={'Alumni'}>Alumni</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    />
                    <Button size="large" variant="outlined" onClick={() => removeTestimonial(index)} color="error" endIcon={<DeleteOutlinedIcon />}>
                      Delete Testimonial
                    </Button>
                  </Stack>
                </Stack>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
        {controlledFields.length < 15 && (
          <Button variant="outlined" color="info" onClick={addTestimonial} endIcon={<AddIcon />}>
            Add another testimonial
          </Button>
        )}
      </Box>
    </section>
  );
};
