import { Avatar, Box, Paper, Typography, useTheme } from '@mui/material';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';

import { useProfile } from '../ProfileContext';

export const SchoolProfileHeader = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const backgroundColor = theme.palette.primary.main;
  const logo = profile.files ? profile.files.find((file) => file.role === FileRoles.FILE_ROLE_MARKETING_LOGO) : null;

  return (
    <Paper sx={{ mb: 1, mt: 1 }}>
      <Box sx={{ pl: 2, height: 100, display: 'flex', alignItems: 'center' }}>
        <Avatar
          src={logo?.fileUrl}
          alt={profile.name}
          sx={{ height: 65, width: 'auto', minWidth: 65 }}
          imgProps={{ style: { width: 'auto', maxWidth: 130, objectFit: 'contain' } }}
          variant="square"
        />
        <Typography
          variant="h4"
          sx={{
            ml: 2,
            pl: 4,
            flex: 1,
            color: '#fff',
            height: '100%',
            backgroundColor,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {profile.name}
        </Typography>
      </Box>
    </Paper>
  );
};
