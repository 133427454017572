import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { ProfileData } from 'types/profile.interface';

export type DayOrBoarding = ProfileData['dayOrBoarding'];

interface DayOrBoardingFilterProps {
  value: DayOrBoarding;
  onChange: (newValue: DayOrBoarding) => void;
  onDiscard: () => void;
}

export const DayOrBoardingFilter = ({ value, onChange, onDiscard }: DayOrBoardingFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Day or boarding
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="day-or-boarding-filter-select-label">Select school type</InputLabel>
        <Select
          labelId="day-or-boarding-filter-select-label"
          id="day-or-boarding-filter-select"
          label="Select school type"
          value={value}
          onChange={(event) => onChange(event.target.value as DayOrBoarding)}
        >
          <MenuItem value="day">Day school only</MenuItem>
          <MenuItem value="boarding">Boarding school only</MenuItem>
          <MenuItem value="dayAndBoarding">Day &amp; boarding school</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
