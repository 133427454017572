import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { CoedStatuses } from 'types/coed-statuses.enum';

interface CoedStatusFilterProps {
  value: CoedStatuses;
  onChange: (newCoedStatus: CoedStatuses) => void;
  onDiscard: () => void;
}

export const CoedStatusFilter = ({ value, onChange, onDiscard }: CoedStatusFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Coeducation Status
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="coed-filter-select-label">Select status</InputLabel>
        <Select
          labelId="coed-filter-select-label"
          id="coed-filter-select"
          label="Select status"
          value={value}
          onChange={(event) => onChange(event.target.value as CoedStatuses)}
        >
          <MenuItem value={CoedStatuses.CoEd}>Coeducational</MenuItem>
          <MenuItem value={CoedStatuses.Male}>Boys only</MenuItem>
          <MenuItem value={CoedStatuses.Female}>Girls only</MenuItem>
        </Select>
      </FormControl>
    </Card>
  );
};
