export enum FilterTypes {
  CoedStatus = 'coedStatus',
  CustomAttribute = 'customAttribute',
  Location = 'location',
  City = 'city',
  ProgramPricing = 'programPricing',
  ProgramAvailability = 'programAvailability',
  PublicOrPrivate = 'publicOrPrivate',
  EsolSupport = 'hasEsolSupport',
  UsDiploma = 'hasUsDiploma',
  AcceptsAge = 'acceptsAge',
  DayOrBoarding = 'dayOrBoarding',
  AcceptsGrade = 'acceptsGrade',
  Subjects = 'subjects',
  Sports = 'sports',
  HomestayProvider = 'homestayProvider',
}
