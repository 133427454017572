import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Stack, Switch, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Control, Controller } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';
import { HiddenSectionHelpPill } from '../HiddenSectionHelpPill';
import { FeaturesEdit } from './FeaturesEdit';

export interface FeaturesSectionProps {
  control: Control<EditableProfileFields>;
}

export const FeaturesSection = ({ control }: FeaturesSectionProps) => {
  return (
    <section id="features-section">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
            School features
          </Typography>
          <Controller
            name="showFeaturesSection"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Switch checked={value} onChange={(event, value) => onChange(value)} />
                {!value && <HiddenSectionHelpPill />}
              </>
            )}
          />
        </Stack>
        <Tooltip
          title={
            <>
              <Typography sx={{ fontWeight: 500 }}>This section can be filtered</Typography>
              <Typography>
                Fill out as many details as possible to appear in filtered searches. More information provided here will allow your school to appear in more
                searches
              </Typography>
            </>
          }
        >
          <HelpOutlineIcon sx={{ color: grey[500] }} />
        </Tooltip>
      </Stack>

      <Controller name="profileFeatures" control={control} render={({ field: { value, onChange } }) => <FeaturesEdit value={value} onChange={onChange} />} />
    </section>
  );
};
