import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, Modal, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { IFile } from 'types/file.interface';

import { FileUploadDropzone } from '../profiles/Edit/FilesUploadDropzone';
import { FileUpload } from '../profiles/Edit/FileUpload';

interface UploadCommissionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const UploadCommissionModal = ({ isOpen, onClose }: UploadCommissionModalProps) => {
  const [uploadingFiles, setUploadingFiles] = useState<File[][]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const fileRole = null;

  const theme = useTheme();

  const onDone = () => {
    onClose();
    setUploadingFiles([]);
  };

  const discardAndClose = () => {
    if (uploadingFiles.length) {
      setUploadingFiles([]);
    }
    onClose();
  };

  const onUploadStart = useCallback((newFiles: File[]) => {
    setIsUploading(true);
    setUploadingFiles((files) => [...files, newFiles]);
  }, []);

  const onUploadComplete = useCallback((newFiles: IFile[]) => {
    setIsUploading(false);
  }, []);

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Modal open={isOpen} aria-labelledby="upload-commission-csv-modal-title" aria-describedby="upload-commission-csv-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Stack flex={1}>
            <Stack direction="row" alignItems="center">
              <Typography id="upload-commission-csv-modal-title" variant="h6" component="h2">
                Upload commission CSV
              </Typography>
              <Box flex={1} />
            </Stack>
            <Box minHeight={0} overflow="auto" flex={1}>
              <Typography id="upload-commission-csv-modal-description" sx={{ mb: 3, mt: 2 }} color="secondary" variant="body2">
                Only commission values will be updated. Do not edit the column order in the CSV.
              </Typography>
              <FileUploadDropzone onUploadStart={onUploadStart} maxFiles={1} mimeTypes={['text/csv']} />
              {uploadingFiles.map((upload, index) => {
                return <FileUpload fileRole={fileRole} fileUrl="/commission-csv" files={upload} onUploadComplete={onUploadComplete} key={index} />;
              })}
            </Box>
            <FormControl fullWidth>
              <Stack direction="row" spacing={1}>
                <LoadingButton fullWidth disabled={isUploading} variant="outlined" onClick={discardAndClose} color="secondary">
                  Cancel
                </LoadingButton>
                <Button fullWidth variant="contained" disabled={isUploading} onClick={onDone} color="primary" endIcon={<TaskAltIcon />}>
                  Done
                </Button>
              </Stack>
            </FormControl>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
