import { useAuth0 } from '@auth0/auth0-react';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Avatar, Box, Button, Link, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';
import { canUserEditMarketingProfile } from 'utils/userRoles';

import { useProfile } from '../../ProfileContext';
import { EnquireDialog } from '../EnquireDialog';

export const ContactSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const { user } = useAuth0<AuthUser>();
  const { enrolmentsUrl } = getConfig();
  const [openEnquireDialog, setOpenEnquireDialog] = useState(false);
  const isEducatiusAgent = user!.userData.contractedWithEducatius;
  const isEducatiusProfile = Boolean(profile?.isExternallyManaged) && Boolean(profile?.educatiusSchoolId);

  const files = profile.files || [];
  const contactImage = files.find((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_CONTACT_IMAGE);

  const shouldRenderContactDetails: boolean = typeof profile.contactName === 'string';

  // if educatius agent viewing Educatius institution
  const shouldRenderEducatiusApplyButton = isEducatiusAgent && isEducatiusProfile;
  // if agent is connected with Enroller institution
  const shouldRenderEnrollerApplyButton = user!.userData.roles.includes('agent') && profile.connected;
  const shouldRenderApplyButton = shouldRenderEducatiusApplyButton || shouldRenderEnrollerApplyButton;
  const applyLink = shouldRenderEducatiusApplyButton ? `https://www.gozapp.com/login.aspx` : `${enrolmentsUrl}/${profile.institutionSlug}/applicants/new`;

  return (
    <>
      <Paper style={{ fontSize: 12, padding: '10px', marginTop: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '8px' }}>
        <Box display="flex" flexDirection="row">
          {shouldRenderContactDetails && contactImage && (
            <Avatar
              alt={profile.contactName}
              src={contactImage.fileUrl}
              sx={{ width: '50px', height: '50px', margin: '10px 0', textAlign: 'center', backgroundColor: '#eee' }}
            />
          )}
          {shouldRenderContactDetails && !contactImage && (
            <Avatar sx={{ width: '50px', height: '50px', margin: '10px 0', textAlign: 'center', backgroundColor: '#eee' }}>
              <PersonOutlinedIcon sx={{ width: '50px', height: '50px' }} />
            </Avatar>
          )}
          <Box display="flex" flexDirection="column" justifyContent="center" mx={1}>
            {profile.contactName && <Typography fontSize={12}>{profile.contactName}</Typography>}
            {profile.contactRole && <Typography fontSize={12}>{profile.contactRole}</Typography>}
          </Box>
        </Box>

        <Button variant="contained" fullWidth onClick={() => (canUserEditMarketingProfile(user!.userData.roles) ? null : setOpenEnquireDialog(true))}>
          {profile.country === 'United States' ? 'Inquire' : 'Enquire'}
          {/* 🤦‍♂️ */}
        </Button>
        {shouldRenderApplyButton && (
          <Link href={applyLink} target="_blank" sx={{ width: '100%' }}>
            <Button variant="contained" fullWidth>
              Apply
            </Button>
          </Link>
        )}
      </Paper>
      {openEnquireDialog && profile.slug && (
        <EnquireDialog open onClose={() => setOpenEnquireDialog(false)} school={profile.institutionSlug} profileSlug={profile.slug} />
      )}
    </>
  );
};
