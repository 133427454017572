import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Box, Button, Container, Menu, MenuItem, Paper, ThemeProvider, Typography, useTheme } from '@mui/material';
import { Footer } from 'components/Footer';
import { Loader } from 'components/Loader';
import { useIsIframed } from 'hooks/use-is-iframed';
import { useThemeOverride } from 'hooks/use-theme-override';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { FileRoles } from 'types/file-roles.enum';

import { useProfile } from '../ProfileContext';
import { AboutSection } from './AboutSection';
import { AcademicSection } from './AcademicSection';
import { ContactSection } from './ContactSection';
import { DocumentsSection } from './DocumentsSection';
import { FeaturesSection } from './FeaturesSection';
import { HighlightsSection } from './HighlightsSection';
import { ImageVideoGallery } from './ImageVideoGallery';
import { LocationSection } from './LocationSection';
import { SchoolProfileHeader } from './SchoolProfileHeader';
import { SidebarInfoPanel } from './SidebarInfoPanel';
import { StudyProgramsSection } from './StudyProgramsSection';
import { TestimonialsSection } from './TestimonialsSection';

type ViewProps = {
  ignorePublishedState?: boolean;
};

export const View = ({ ignorePublishedState }: ViewProps) => {
  const navigate = useNavigate();
  const isIframed = useIsIframed();

  const {
    state: { profile, status: profileStatus, isSharedView },
  } = useProfile();

  const [quickLinksAnchorEl, setQuickLinksAnchorEl] = useState<null | HTMLElement>(null);
  const isQuickLinksOpen = Boolean(quickLinksAnchorEl);

  const [sectionAboutEl, setSectionAboutEl] = useState<HTMLElement | null>(null);
  const [sectionHighlightsEl, setSectionHighlightsEl] = useState<HTMLElement | null>(null);
  const [sectionStudyProgramsEl, setSectionStudyProgramsEl] = useState<HTMLElement | null>(null);
  const [sectionAcademicEl, setSectionAcademicEl] = useState<HTMLElement | null>(null);
  const [sectionTestimonialsEl, setSectionTestimonialsEl] = useState<HTMLElement | null>(null);
  const [sectionGalleryEl, setSectionGalleryEl] = useState<HTMLElement | null>(null);
  const [sectionDocumentsEl, setSectionDocumentsEl] = useState<HTMLElement | null>(null);
  const [sectionFeaturesEl, setSectionFeaturesEl] = useState<HTMLElement | null>(null);
  const [sectionLocationEl, setSectionLocationEl] = useState<HTMLElement | null>(null);

  const handleClickQuickLinks = (event: React.MouseEvent<HTMLButtonElement>) => setQuickLinksAnchorEl(event.currentTarget);
  const handleCloseQuickLinks = () => setQuickLinksAnchorEl(null);
  const navigateToSection = (sectionRef: HTMLElement | null) => () => {
    setTimeout(() => sectionRef?.scrollIntoView(), 0);
    if (!isIframed && sectionRef) {
      setTimeout(() => window.scrollBy(0, -85), 0); // past the fixed top bar
    }
    handleCloseQuickLinks();
  };

  const theme = useTheme();

  // Push an overridden theme into this component and children to use the school's colour instead of Enroller green
  const profileColor = profile?.colour || '#3EAD47';
  const profileTheme = useThemeOverride(profileColor);

  if (profileStatus !== 'success') {
    return <Loader />;
  }

  if (!profile || (!profile.isPublished && !ignorePublishedState)) {
    return <h1>Coming soon...</h1>;
  }

  // TODO: Maybe just reduce these to a const number. We really only care about their presence/absence in this
  // component to determine which section subcomponents to render.
  const files = profile.files || [];
  const photos: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_SCHOOL_PHOTOS);
  const prospectus: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_PROSPECTUS);
  const marketingMaterials: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_MARKETING_MATERIALS);
  const contractsAgreements: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_CONTRACTS_AGREEMENTS);
  const videoUrls: string[] = profile.videoUrls || [];
  const documents: IFile[] = [...marketingMaterials, ...prospectus, ...contractsAgreements];

  const shouldRenderContact: boolean = !isSharedView;
  const shouldRenderGallery: boolean = (photos.length || 0) + (videoUrls.length || 0) > 0;
  const shouldRenderDocuments: boolean = (documents.length || 0) > 0;
  const shouldRenderStudyPrograms: boolean = profile.studyPrograms?.length > 0;
  const shouldRenderLocation: boolean = Boolean(profile.latLng);

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Container sx={{ p: 0 }}>
        <ThemeProvider theme={profileTheme}>
          <SchoolProfileHeader />
          <Box display="flex" flexDirection="row">
            <nav style={{ width: 290, marginRight: 10 }}>
              {!isSharedView && isIframed && (
                <Button
                  sx={{ marginBottom: '10px', padding: '8px', backgroundColor: theme.palette.background.paper }}
                  variant="outlined"
                  onClick={() => navigate('/marketing')}
                  startIcon={<KeyboardArrowLeftIcon />}
                  fullWidth
                >
                  Marketplace
                </Button>
              )}
              <Button
                id="quick-links-button"
                variant="outlined"
                sx={{ marginBottom: '10px', padding: '8px', backgroundColor: theme.palette.background.paper }}
                aria-controls={isQuickLinksOpen ? 'quick-links-nav-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isQuickLinksOpen ? 'true' : undefined}
                onClick={handleClickQuickLinks}
                startIcon={<KeyboardArrowDownIcon />}
                fullWidth
              >
                Quick Links
              </Button>
              <Menu
                id="quick-links-nav-menu"
                anchorEl={quickLinksAnchorEl}
                open={isQuickLinksOpen}
                onClose={handleCloseQuickLinks}
                MenuListProps={{
                  'aria-labelledby': 'quick-links-button',
                }}
              >
                <MenuItem onClick={navigateToSection(sectionAboutEl)}>ABOUT</MenuItem>
                {profile.showHighlightsSection && <MenuItem onClick={navigateToSection(sectionHighlightsEl)}>HIGHLIGHTS</MenuItem>}
                {shouldRenderStudyPrograms && <MenuItem onClick={navigateToSection(sectionStudyProgramsEl)}>PROGRAMS &amp; PRICING</MenuItem>}
                {profile.showAcademicInfoSection && <MenuItem onClick={navigateToSection(sectionAcademicEl)}>ACADEMIC</MenuItem>}
                {profile.showFeaturesSection && <MenuItem onClick={navigateToSection(sectionFeaturesEl)}>SCHOOL FEATURES</MenuItem>}
                {shouldRenderLocation && <MenuItem onClick={navigateToSection(sectionLocationEl)}>LOCATION</MenuItem>}
                {profile.showTestimonialsSection && <MenuItem onClick={navigateToSection(sectionTestimonialsEl)}>TESTIMONIALS</MenuItem>}
                {shouldRenderGallery && <MenuItem onClick={navigateToSection(sectionGalleryEl)}>GALLERY</MenuItem>}
                {shouldRenderDocuments && <MenuItem onClick={navigateToSection(sectionDocumentsEl)}>DOCUMENTS</MenuItem>}
              </Menu>
              <SidebarInfoPanel />
              {shouldRenderContact && <ContactSection />}
              {isIframed && <img style={{ width: '100%', paddingTop: '20px' }} src="/powered-by-enroller.png" alt="Powered by Enroller" />}
            </nav>

            <main style={{ width: '100%', overflowX: 'auto' }}>
              <section ref={(el) => setSectionAboutEl(el)}>
                <AboutSection />
              </section>

              {profile.showHighlightsSection && (
                <section ref={(el) => setSectionHighlightsEl(el)}>
                  <HighlightsSection />
                </section>
              )}

              {shouldRenderStudyPrograms && (
                <section ref={(el) => setSectionStudyProgramsEl(el)}>
                  <StudyProgramsSection />
                </section>
              )}

              {profile.showAcademicInfoSection && (
                <section ref={(el) => setSectionAcademicEl(el)}>
                  <AcademicSection />
                </section>
              )}

              {profile.showFeaturesSection && (
                <section ref={(el) => setSectionFeaturesEl(el)}>
                  <FeaturesSection />
                </section>
              )}

              {shouldRenderLocation && (
                <section ref={(el) => setSectionLocationEl(el)}>
                  <LocationSection />
                </section>
              )}

              {profile.showTestimonialsSection && (
                <section ref={(el) => setSectionTestimonialsEl(el)}>
                  <TestimonialsSection />
                </section>
              )}

              {shouldRenderGallery && (
                <section ref={(el) => setSectionGalleryEl(el)}>
                  <Paper style={{ padding: 20, marginBottom: '20px' }}>
                    <Typography variant="h5" color={profile.colour}>
                      Gallery
                    </Typography>
                    <br />
                    <ImageVideoGallery imageFiles={photos} videoUrls={videoUrls} />
                  </Paper>
                </section>
              )}

              {shouldRenderDocuments && (
                <section ref={(el) => setSectionDocumentsEl(el)}>
                  <DocumentsSection />
                </section>
              )}
            </main>
          </Box>
        </ThemeProvider>
      </Container>
      <Box flex={1} />
      {!isIframed && <Footer />}
    </Box>
  );
};
