import { Divider } from '@mui/material';
import { Control, FieldErrors } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';
import { AcademicInfoSection } from './AcademicInfoSection';
import { FeaturesSection } from './FeaturesSection';
import { TestimonialsSection } from './TestimonialsSection';

interface AcademicInfoEditTabProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const AcademicInfoEditTab = ({ errors, control }: AcademicInfoEditTabProps) => {
  return (
    <>
      <AcademicInfoSection control={control} errors={errors} />
      <Divider sx={{ my: 3 }} />
      <FeaturesSection control={control} />
      <Divider sx={{ my: 3 }} />
      <TestimonialsSection control={control} errors={errors} />
    </>
  );
};
