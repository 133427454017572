import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getConfig } from 'utils/config';

export const Login = () => {
  const { loginWithRedirect, isLoading, isAuthenticated } = useAuth0();
  const { auth0Audience } = getConfig();

  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Navigate to="/marketing" />;
  }

  // We do not set a `prompt` as auth0 will automatically infer which one we need
  // When a new user logs in, it will use the `consent` prompt which is needed for first time logins
  // After that it will use the `silent` login and not prompt them to do anything
  loginWithRedirect({
    appState: {
      returnTo: '/marketing',
    },
    authorizationParams: {
      audience: auth0Audience,
      scope: 'openid profile email offline_access read:current_user',
    },
  });

  return <Loader />;
};
