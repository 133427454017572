import { useAuth0 } from '@auth0/auth0-react';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import ShareIcon from '@mui/icons-material/Share';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { Box, CircularProgress, Divider, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';
import { yellow } from '@mui/material/colors';
import { Pill } from 'components/Pill';
import { useAsync } from 'hooks/use-async';
import { useIsIframed } from 'hooks/use-is-iframed';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { HomestayProvider } from 'types/homestay-provider.enum';
import { PricingRegion } from 'types/pricing-region.enum';
import { Curriculum, ProfileData } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';
import { timeformat } from 'utils';
import { getUserId } from 'utils/user';

import { useProfile } from '../ProfileContext';

export const SidebarInfoPanel = () => {
  const isIframed = useIsIframed();
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };
  const { user } = useAuth0<AuthUser>();
  const { enqueueSnackbar } = useSnackbar();

  const shouldRenderUserActions = !isIframed && !isSharedView;

  const coedStatusText = profile.coedStatus
    ? {
        [CoedStatuses.Male]: 'Boys only',
        [CoedStatuses.Female]: 'Girls only',
        [CoedStatuses.CoEd]: 'Coeducational',
      }[profile.coedStatus]
    : null;
  const boardingStatusText = {
    day: 'Day school only',
    boarding: 'Boarding school only',
    dayAndBoarding: 'Day school with Boarding facilities',
  }[profile.dayOrBoarding];

  const religiousAffiliationText = profile.religiousAffiliation ? `${profile.religiousAffiliation} Affiliation` : 'No Religious Affiliation';

  // TODO: Consider whether this really needs separate endpoints to set a single bool field?
  const favouriteProfileUrl = `/favourites/profiles/${profile.slug}`;
  const [, addFavStatus, addFav] = useAsync(favouriteProfileUrl, 'PUT', false);
  const [, delFavStatus, delFav] = useAsync(favouriteProfileUrl, 'DELETE', false);
  const [isFavourite, setIsFavourite] = useState(profile.favourite);
  useEffect(() => {
    if (addFavStatus === 'success') setIsFavourite(true);
  }, [addFavStatus]);
  useEffect(() => {
    if (delFavStatus === 'success') setIsFavourite(false);
  }, [delFavStatus]);
  const origin = window.location.origin;
  const region: PricingRegion = user?.userData.primaryMarketCountryId === 156 ? PricingRegion.China : PricingRegion.RestOfWorld;
  const shareLink = `${origin}/marketing/profiles/${profile.id}/shared-view/p/${region}/u/${getUserId(user!)}/`;
  const copyShareLink = async () => {
    await navigator.clipboard.writeText(shareLink);
    enqueueSnackbar('Copied to clipboard!', { variant: 'success' });
  };

  const isInternationalCurriculum = (c: Curriculum) => ['International Baccalaureate', 'Cambridge International'].includes(c.name);
  const displayCurriculums = profile.curriculums.filter(isInternationalCurriculum).map((c) => c.name);

  // Highlight special homestay providers as pills instead of text.
  const homestayPillProviders = [HomestayProvider.Educatius, HomestayProvider.AustralianHomestayNetwork];
  const showHomestayProviderAsPill = homestayPillProviders.includes(profile.homestayAccomodation!);
  const homestayProviderEl = showHomestayProviderAsPill ? (
    <Pill label={profile.homestayAccomodation} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
  ) : (
    profile.homestayAccomodation
  );

  return (
    <Paper sx={{ padding: '8px' }}>
      {shouldRenderUserActions && (
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <IconButton
            sx={{ p: 0.5 }}
            size="small"
            onClick={() => (isFavourite ? delFav() : addFav())}
            disabled={addFavStatus === 'pending' || delFavStatus === 'pending'}
          >
            {addFavStatus === 'pending' || delFavStatus === 'pending' ? (
              <CircularProgress size={24} />
            ) : (
              <Tooltip title={isFavourite ? 'Starred' : 'Unstarred'} placement="top" arrow>
                {isFavourite ? <StarIcon sx={{ color: yellow[700] }} /> : <StarBorderIcon />}
              </Tooltip>
            )}
          </IconButton>
          {profile.connected && (
            <Tooltip title="Registered" placement="top" arrow>
              <HowToRegIcon color="primary" />
            </Tooltip>
          )}
          {profile.lastEnquiredAt && (
            <Tooltip title={`Enquired on ${timeformat(profile.lastEnquiredAt)}`} placement="top" arrow>
              <QuestionAnswerIcon color="secondary" />
            </Tooltip>
          )}
          <IconButton sx={{ p: 0.5 }} size="small" onClick={copyShareLink}>
            <Tooltip title="Copy profile link to share" placement="top" arrow>
              <ShareIcon color="primary" />
            </Tooltip>
          </IconButton>
        </Stack>
      )}
      <Box m={1}>
        <Typography>{profile.city}</Typography>
        {profile.state !== profile.city && <Typography>{profile.state}</Typography>}
        <Typography>{profile.country}</Typography>
      </Box>
      <Divider />
      {displayCurriculums.length > 0 && (
        <>
          <Typography m={1}>International curriculum:</Typography>
          {displayCurriculums.map((name) => (
            <Pill key={name} label={name} color="success" icon={<StarOutlineIcon />} sx={{ py: 2, mb: 0.5, fontWeight: 600, fontSize: 9 }} />
          ))}
        </>
      )}
      <Typography m={1}>{profile.isPrivateSchool ? 'Private school' : 'Public school'}</Typography>
      {boardingStatusText && <Typography m={1}>{boardingStatusText}</Typography>}
      {profile.homestayAccomodation && <Typography m={1}>Homestay managed by {homestayProviderEl}</Typography>}
      {coedStatusText && (
        <Typography m={1} sx={{ textTransform: 'capitalize' }}>
          {coedStatusText}
        </Typography>
      )}
      {profile.totalStudentCount && <Typography m={1}>{profile.totalStudentCount} total students</Typography>}
      {profile.averageClassSize && <Typography m={1}>{profile.averageClassSize} average class size</Typography>}
      {profile.internationalStudentPercentage && <Typography m={1}>{profile.internationalStudentPercentage}% international students</Typography>}
      <Typography m={1}>{religiousAffiliationText}</Typography>
    </Paper>
  );
};
