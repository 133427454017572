import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';

import { FilterFlag } from '../filter-flag.type';

interface ProgramAvailabilityFilterProps {
  value: FilterFlag;
  onChange: (programAvailability: FilterFlag) => void;
  onDiscard: () => void;
}

export const ProgramAvailabilityFilter = ({ onDiscard }: ProgramAvailabilityFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Program Availability
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1}>
        <CheckIcon />
        <Typography pt={'5px'}>Has spaces available for Fall 2023 (Northern hemisphere schools only)</Typography>
      </Stack>
    </Card>
  );
};
