import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, Button, MenuItem, MenuList, Popover } from '@mui/material';
import { AxiosRequestConfig } from 'axios';
import { useAsync } from 'hooks/use-async';
import fileDownload from 'js-file-download';
import { MouseEvent, useEffect, useState } from 'react';

import { UploadCommissionModal } from './UploadCommissionModal';

export const CommissionCSVDownloadButton = () => {
  const [commissionPopoverEl, setCommissionPopoverEl] = useState<HTMLButtonElement | null>(null);
  const isCommissionPopoverOpen = Boolean(commissionPopoverEl);
  const commissionPopoverId = isCommissionPopoverOpen ? 'commission-popover' : undefined;
  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
  const extraAxiosConfig: Partial<AxiosRequestConfig> = { responseType: 'blob' };
  const [res, status, startDownload] = useAsync('/commission-csv', 'GET', false, extraAxiosConfig);

  const closeUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  useEffect(() => {
    if (status === 'success' && res) {
      fileDownload(res, 'commission.csv');
    }
  }, [status, res]);

  const uploadFile = () => {
    setIsUploadModalOpen(true);
  };
  return (
    <>
      <Button
        aria-describedby={commissionPopoverId}
        color="secondary"
        variant="outlined"
        sx={{
          color: 'secondary',
          backgroundColor: 'secondary',
          mr: 1,
        }}
        onClick={(event: MouseEvent<HTMLButtonElement>) => setCommissionPopoverEl(event.currentTarget)}
      >
        Commission CSV
        <ArrowDropDownIcon />
      </Button>
      <Popover
        id={commissionPopoverId}
        open={isCommissionPopoverOpen}
        anchorEl={commissionPopoverEl}
        onClose={() => setCommissionPopoverEl(null)}
        disablePortal
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={1} width="150px">
          <MenuList id="commission-button-actions-menu">
            <MenuItem key="download" disabled={status === 'pending'} onClick={() => startDownload()}>
              <FileDownloadIcon /> Download
            </MenuItem>
            <MenuItem key="upload" onClick={uploadFile}>
              <UploadIcon /> Upload
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
      <UploadCommissionModal isOpen={isUploadModalOpen} onClose={closeUploadModal} />
    </>
  );
};
