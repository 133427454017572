import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Card, CardMedia, Chip, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useMemo, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileSummary } from 'types/profile.interface';
import { StudyProgram } from 'types/study-program.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';
import { AuthUser } from 'types/user.interface';
import { getOptimisedImageUrl, ImageEditOptions } from 'utils/getOptimisedImageUrl';
import { canUserEditMarketingProfile } from 'utils/userRoles';

import { FilterParams } from './filtering/filter-params.interface';

interface ProfileCardProps {
  profile: ProfileSummary | null;
  filterParams: Readonly<FilterParams>;
  flagSrc: string | null;
}

export const ProfileCard = ({ profile, filterParams, flagSrc }: ProfileCardProps) => {
  const navigate = useNavigate();
  const { user } = useAuth0<AuthUser>();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(true);
  const [isBannerLoading, setIsBannerLoading] = useState<boolean>(true);
  const [isLogoLoading, setIsLogoLoading] = useState<boolean>(true);
  const [isFlagLoading, setIsFlagLoading] = useState<boolean>(true);

  const [isBannerError, setIsBannerError] = useState<boolean>(false);
  const [isLogoError, setIsLogoError] = useState<boolean>(false);
  const [isFlagError, setIsFlagError] = useState<boolean>(false);

  const logo = profile?.files ? profile?.files.find((file) => file.role === FileRoles.FILE_ROLE_MARKETING_LOGO) : null;

  const bannerEditOptions: ImageEditOptions = {
    resize: { width: 850, fit: 'cover' },
  };

  const logoEditOptions: ImageEditOptions = {
    resize: { width: 75, fit: 'cover' },
  };

  const cardMediaImagePath = profile ? getOptimisedImageUrl(profile.firstImagePath, bannerEditOptions) : '';
  const logoImagePath = logo ? getOptimisedImageUrl(logo.fileUrl!, logoEditOptions) : '';

  // Profile data loading
  useEffect(() => {
    setIsProfileLoading(profile === null);
  }, [profile]);

  // Banner image loading
  useEffect(() => {
    const bannerImage = new Image();
    bannerImage.onload = () => setIsBannerLoading(false);
    bannerImage.onerror = () => {
      setIsBannerError(true);
      setIsBannerLoading(false);
    };
    if (profile?.firstImagePath) bannerImage.src = cardMediaImagePath;
    else setIsBannerLoading(false);
  }, [profile, cardMediaImagePath]);

  // Logo image loading
  useEffect(() => {
    const logoImage = new Image();
    logoImage.onload = () => setIsLogoLoading(false);
    logoImage.onerror = () => {
      setIsLogoError(true);
      setIsLogoLoading(false);
    };
    if (logo?.fileUrl) logoImage.src = logoImagePath;
    else setIsLogoLoading(false);
  }, [logo, logoImagePath]);

  // Flag image loading
  useEffect(() => {
    const flagImage = new Image();
    flagImage.onload = () => setIsFlagLoading(false);
    flagImage.onerror = () => {
      setIsFlagError(true);
      setIsFlagLoading(false);
    };
    if (flagSrc) flagImage.src = flagSrc;
    else setIsLogoLoading(false);
  }, [flagSrc]);

  const onCardClick = () => {
    if (!profile) return;
    const gaEvent = { category: 'Institution Profile', label: profile.slug, action: 'Clicked' };
    if (profile.forceRedirectToWebsite) {
      // External institution profile redirect e.g. to micro site
      ReactGA.event(gaEvent);
      window.open(profile.websiteUrl, '_blank');
    } else if (canUserEditMarketingProfile(user!.userData.roles)) {
      // Distinguish institution users editing profiles from non-institution user clicks
      gaEvent.action = 'Edited';
      ReactGA.event(gaEvent);
      navigate(`/marketing/profiles/${profile.slug}/edit`);
    } else {
      // Regular non-institutional user path
      ReactGA.event(gaEvent);
      navigate(`/marketing/profiles/${profile.slug}/view`);
    }
  };

  const skeletonBgColor = '#4242421C'; // Not available on theme object, copied via inspector

  const avatar = (
    <Avatar
      sx={{ height: 75, width: 75, cursor: 'pointer' }}
      imgProps={{ style: { height: 75, width: 75, objectFit: 'contain' } }}
      alt={profile?.name || 'School Logo'}
      variant="square"
      src={!isLogoError ? logoImagePath : ''}
      onClick={onCardClick}
    />
  );

  // Price tag program period logic
  // If profiles are currently filtered to a program period, display that program's price.
  // Otherwise, show the academic year program price, else if none then the calendar year program price, otherwise no price.
  const filterProgramPeriod = filterParams.pricingProgramPeriod;
  const getStudyProgramPeriod = (periodType: StudyProgramPeriodType) => (p: StudyProgram) => p.periodType === periodType && p.studyProgramPeriods.length > 0;
  const studyProgram = filterProgramPeriod
    ? profile?.studyPrograms?.find(getStudyProgramPeriod(filterProgramPeriod))
    : profile?.studyPrograms?.find(getStudyProgramPeriod(StudyProgramPeriodType.AcademicYear)) ||
      profile?.studyPrograms?.find(getStudyProgramPeriod(StudyProgramPeriodType.CalendarYear));
  const costFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: profile?.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
      }),
    [profile?.currency],
  );
  // Use first study period under the relevant program
  // TODO: Confirm this is fine, or maybe use the "Next starting" period ?
  const studyProgramPeriod = studyProgram?.studyProgramPeriods[0];
  const priceTag = studyProgramPeriod && `${costFormatter.format(parseFloat(studyProgramPeriod.basePrice))} ${profile?.currency}`;

  const renderAddress = () => {
    const city = profile!.city && `${profile!.city}, `;
    const stateOrCountry = profile!.state !== profile!.city ? profile!.state : profile!.country;
    return `${city}${stateOrCountry}`;
  };

  /**
   * TODO: Wrap the top of the card in CardActions so click triggers MUI ripple
   * @example https://codesandbox.io/s/cardmedia-onload-tuqru?file=/demo.js
   */

  return (
    <Grid item sm={12} md={6} lg={3} sx={{ width: '100%' }}>
      <Card
        sx={{ height: '280px', boxShadow: isHovered ? '0px 10px 25px -5px rgb(0 0 0 / 20%)' : '' }}
        variant="elevation"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {isProfileLoading || isBannerLoading ? (
          <Skeleton
            variant="rectangular"
            animation={isBannerError ? false : 'wave'}
            height={170}
            onClick={onCardClick}
            sx={{ cursor: 'pointer', width: '100%' }}
          />
        ) : (
          <CardMedia sx={{ height: 170, cursor: 'pointer', bgcolor: skeletonBgColor }} image={cardMediaImagePath} title={profile!.name} onClick={onCardClick}>
            <Stack direction="column" width="100%" height="100%" alignItems="left">
              {!isFlagLoading && (
                <Box>
                  <Chip
                    label={profile?.country || ''}
                    icon={isFlagError ? <></> : <img src={flagSrc || ''} alt={`${profile?.country} flag`.trim()} width="16px" height="16px" />}
                    size="small"
                    sx={{ m: 1, backgroundColor: 'background.paper' }}
                  />
                </Box>
              )}
              <Box flex="1" />
              {priceTag && (
                <Box>
                  <Chip label={priceTag} size="small" sx={{ m: 1, backgroundColor: 'background.paper' }} />
                </Box>
              )}
            </Stack>
          </CardMedia>
        )}

        <Stack direction="row" sx={{ px: 2, py: 2 }}>
          {isProfileLoading || isLogoLoading ? (
            <Skeleton variant="rectangular" animation={isLogoError ? false : 'wave'} onClick={onCardClick} sx={{ cursor: 'pointer', height: 75, width: 75 }} />
          ) : (
            avatar
          )}
          <Stack direction="column" sx={{ ml: 2 }}>
            {isProfileLoading ? (
              <>
                <Skeleton variant="text" animation="wave" width="260px" sx={{ mt: 0.5, mb: 0.8 }} />
                <Skeleton variant="text" animation="wave" width="160px" />
              </>
            ) : (
              <>
                <Typography
                  gutterBottom
                  variant="h4"
                  mt={0.5}
                  mb={0.8}
                  sx={{ fontSize: 18, fontWeight: 500, color: grey[900], cursor: 'pointer' }}
                  onClick={onCardClick}
                >
                  {profile!.name}
                </Typography>
                <Typography variant="body2" mb={0.4}>
                  {renderAddress()}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};
