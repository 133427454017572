import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo, useState } from 'react';
import { CoedStatuses } from 'types/coed-statuses.enum';
import { HomestayProvider } from 'types/homestay-provider.enum';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';

import { useFilters } from '../FiltersContext';
import { FilterParams } from './filter-params.interface';
import { FilterTypes } from './filter-types.enum';
import { AcceptsAgeFilter } from './filters/AcceptsAgeFilter';
import { AcceptsGradeFilter } from './filters/AcceptsGradeFilter';
import { CityFilter } from './filters/CityFilter';
import { CoedStatusFilter } from './filters/CoedStatusFilter';
import { CustomAttributeFilter } from './filters/CustomAttributeFilter';
import { DayOrBoardingFilter } from './filters/DayOrBoardingFilter';
import { EsolSupportFilter } from './filters/EsolSupportFilter';
import { HomestayProviderFilter } from './filters/HomestayProviderFilter';
import { LocationFilter, LocationTriple } from './filters/LocationFilter';
import { ProgramAvailabilityFilter } from './filters/ProgramAvailabilityFilter';
import { ProgramPricingFilter } from './filters/ProgramPricingFilter';
import { PublicOrPrivateFilter } from './filters/PublicOrPrivate';
import { SportsFilter } from './filters/SportsFilter';
import { SubjectsFilter } from './filters/SubjectsFilter';
import { UsDiplomaFilter } from './filters/UsDiplomaFilter';
import { getInitialFiltersFromParams } from './get-initial-filters-from-params';

export interface FiltersSelectorPanelProps {
  onClose: () => void;
}

export const FiltersSelectorPanel = ({ onClose }: FiltersSelectorPanelProps) => {
  const {
    state: { filters: values },
    updateFilters: onChange,
  } = useFilters();
  const activeFilters = getInitialFiltersFromParams(values);

  const [getFilterOptionsRes] = useAsync<string[]>('/profile-filters');
  const [newFilter, setNewFilter] = useState('');

  // Avoid looping repeatedly through the response array
  const filterOptions = useMemo(() => new Set(getFilterOptionsRes || []), [getFilterOptionsRes]);

  // Filter options which are already active should be hidden
  const inactiveFilterSelectorOptions = useMemo(() => {
    const inactiveOptions = new Set(filterOptions);
    activeFilters.forEach((filter) => inactiveOptions.delete(filter));
    return inactiveOptions;
  }, [filterOptions, activeFilters]);

  // Hide the filter selector altogether while all the options are active and there are no more available to select
  const showFilterSelector = inactiveFilterSelectorOptions.size > 0;

  const location: LocationTriple = {
    country: values.country || '',
    state: values.state || '',
    city: values.city || '',
  };

  const onDiscard = (fields: (keyof FilterParams)[]) => {
    const temp = { ...values };
    fields.forEach((field) => {
      delete temp[field];
    });
    onChange(temp);
  };

  const resolveFilter = (filterType: FilterTypes) => {
    switch (filterType) {
      case FilterTypes.CoedStatus:
        return (
          <CoedStatusFilter
            key={FilterTypes.CoedStatus}
            value={values.coedStatus || CoedStatuses.CoEd}
            onChange={(coedStatus) => onChange({ ...values, coedStatus })}
            onDiscard={() => onDiscard(['coedStatus'])}
          />
        );
      case FilterTypes.Location:
        return (
          <LocationFilter
            key={FilterTypes.Location}
            value={location}
            onChange={(newLocation: LocationTriple) => onChange({ ...values, ...newLocation })}
            onDiscard={() => onDiscard(['country', 'state', 'city'])}
          />
        );
      case FilterTypes.City:
        return (
          <CityFilter
            key={FilterTypes.City}
            value={values.city || ''}
            onChange={(city) => onChange({ ...values, city })}
            onDiscard={() => onDiscard(['city'])}
          />
        );
      case FilterTypes.ProgramPricing:
        return (
          <ProgramPricingFilter
            key={FilterTypes.ProgramPricing}
            pricingProgramPeriod={(values.pricingProgramPeriod || '') as StudyProgramPeriodType}
            pricingMinUsd={(values.pricingMinUsd || '') as StudyProgramPeriod['basePriceUsd']}
            pricingMaxUsd={(values.pricingMaxUsd || '') as StudyProgramPeriod['basePriceUsd']}
            onProgramPeriodChange={(pricingProgramPeriod: StudyProgramPeriodType) => onChange({ ...values, pricingProgramPeriod })}
            onMinUsdChange={(pricingMinUsd: StudyProgramPeriod['basePriceUsd']) => onChange({ ...values, pricingMinUsd })}
            onMaxUsdChange={(pricingMaxUsd: StudyProgramPeriod['basePriceUsd']) => onChange({ ...values, pricingMaxUsd })}
            onDiscard={() => onDiscard(['pricingProgramPeriod', 'pricingMinUsd', 'pricingMaxUsd'])}
          />
        );
      case FilterTypes.ProgramAvailability:
        return (
          <ProgramAvailabilityFilter
            key={FilterTypes.ProgramAvailability}
            value={values.programAvailability}
            onChange={(programAvailability) => onChange({ ...values, programAvailability })}
            onDiscard={() => onDiscard(['programAvailability'])}
          />
        );
      case FilterTypes.PublicOrPrivate:
        return (
          <PublicOrPrivateFilter
            key={FilterTypes.PublicOrPrivate}
            value={values.isPrivateSchool || 'false'}
            onChange={(isPrivateSchool) => onChange({ ...values, isPrivateSchool })}
            onDiscard={() => onDiscard(['isPrivateSchool'])}
          />
        );
      case FilterTypes.EsolSupport:
        return (
          <EsolSupportFilter
            key={FilterTypes.EsolSupport}
            value={values.hasEsolSupport}
            onChange={(hasEsolSupport) => onChange({ ...values, hasEsolSupport })}
            onDiscard={() => onDiscard(['hasEsolSupport'])}
          />
        );
      case FilterTypes.UsDiploma:
        return (
          <UsDiplomaFilter
            key={FilterTypes.UsDiploma}
            value={values.hasUsDiploma}
            onChange={(hasUsDiploma) => onChange({ ...values, hasUsDiploma })}
            onDiscard={() => onDiscard(['hasUsDiploma'])}
          />
        );
      case FilterTypes.DayOrBoarding:
        return (
          <DayOrBoardingFilter
            key={FilterTypes.DayOrBoarding}
            value={values.dayOrBoarding!}
            onChange={(dayOrBoarding) => onChange({ ...values, dayOrBoarding })}
            onDiscard={() => onDiscard(['dayOrBoarding'])}
          />
        );
      case FilterTypes.CustomAttribute:
        return (
          <CustomAttributeFilter
            key={FilterTypes.CustomAttribute}
            value={values.customAttribute || ''}
            onChange={(customAttribute) => onChange({ ...values, customAttribute })}
            onDiscard={() => onDiscard(['customAttribute'])}
          />
        );
      case FilterTypes.AcceptsAge:
        return (
          <AcceptsAgeFilter
            key={FilterTypes.AcceptsAge}
            value={values.acceptsAge || undefined}
            onChange={(acceptsAge) => onChange({ ...values, acceptsAge })}
            onDiscard={() => onDiscard(['acceptsAge'])}
          />
        );
      case FilterTypes.AcceptsGrade:
        return (
          <AcceptsGradeFilter
            key={FilterTypes.AcceptsGrade}
            value={values.acceptsGrade || undefined}
            onChange={(acceptsGrade) => onChange({ ...values, acceptsGrade })}
            onDiscard={() => onDiscard(['acceptsGrade'])}
          />
        );
      case FilterTypes.Subjects:
        return (
          <SubjectsFilter
            key={FilterTypes.Subjects}
            value={values.subjects || []}
            onChange={(subjects) => onChange({ ...values, subjects })}
            onDiscard={() => onDiscard(['subjects'])}
          />
        );
      case FilterTypes.Sports:
        return (
          <SportsFilter
            key={FilterTypes.Sports}
            value={values.sports || []}
            onChange={(sports) => onChange({ ...values, sports })}
            onDiscard={() => onDiscard(['sports'])}
          />
        );
      case FilterTypes.HomestayProvider:
        return (
          <HomestayProviderFilter
            key={FilterTypes.HomestayProvider}
            value={values.homestayProvider || HomestayProvider.Any}
            onChange={(homestayProvider) => onChange({ ...values, homestayProvider })}
            onDiscard={() => onDiscard(['homestayProvider'])}
          />
        );
      default:
        return <></>;
    }
  };

  const addActiveFilter = (filter: FilterTypes) => {
    // Set defaults for new filters where the input's default value is not empty
    switch (filter) {
      case FilterTypes.CoedStatus:
        onChange({ ...values, coedStatus: CoedStatuses.CoEd });
        break;
      case FilterTypes.PublicOrPrivate:
        onChange({ ...values, isPrivateSchool: 'false' });
        break;
      case FilterTypes.DayOrBoarding:
        onChange({ ...values, dayOrBoarding: 'day' });
        break;
      case FilterTypes.EsolSupport:
        onChange({ ...values, hasEsolSupport: 'true' });
        break;
      case FilterTypes.UsDiploma:
        onChange({ ...values, hasUsDiploma: 'true' });
        break;
      case FilterTypes.ProgramAvailability:
        onChange({ ...values, programAvailability: 'true' });
        break;
      case FilterTypes.Sports:
        onChange({ ...values, sports: [] });
        break;
      case FilterTypes.Subjects:
        onChange({ ...values, subjects: [] });
        break;
      case FilterTypes.Location:
        onChange({ ...values, country: undefined, state: undefined, city: undefined });
        break;
      case FilterTypes.ProgramPricing:
        onChange({ ...values, pricingProgramPeriod: undefined, pricingMinUsd: undefined, pricingMaxUsd: undefined });
        break;
      case FilterTypes.HomestayProvider:
        onChange({ ...values, homestayProvider: HomestayProvider.Any });
        break;
      default:
        onChange({ ...values, [filter]: undefined });
    }
    setNewFilter('');
  };

  return (
    <Box px={3} py={2}>
      <Stack direction="row">
        <Typography variant="h6" fontWeight="600" mb={2}>
          Filters
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Stack>
      {activeFilters.map(resolveFilter)}
      {showFilterSelector && (
        <Select
          fullWidth
          sx={{ mt: 1, width: '320px' }}
          size="small"
          value={newFilter}
          onChange={(event) => addActiveFilter(event.target.value as FilterTypes)}
          displayEmpty
        >
          <MenuItem disabled value="" sx={{ display: 'none' }}>
            Select filter
          </MenuItem>
          {inactiveFilterSelectorOptions.has(FilterTypes.CoedStatus) && <MenuItem value={FilterTypes.CoedStatus}>Coeducation status</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.ProgramAvailability) && (
            <MenuItem value={FilterTypes.ProgramAvailability}>Program Availability</MenuItem>
          )}
          {inactiveFilterSelectorOptions.has(FilterTypes.ProgramPricing) && <MenuItem value={FilterTypes.ProgramPricing}>Program type &amp; price</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.Location) && <MenuItem value={FilterTypes.Location}>Location</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.City) && <MenuItem value={FilterTypes.City}>City</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.PublicOrPrivate) && <MenuItem value={FilterTypes.PublicOrPrivate}>Public/Private</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.EsolSupport) && <MenuItem value={FilterTypes.EsolSupport}>ESOL Support</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.UsDiploma) && <MenuItem value={FilterTypes.UsDiploma}>US Diploma</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.AcceptsAge) && <MenuItem value={FilterTypes.AcceptsAge}>Accepts Age</MenuItem>}
          {/* TODO: Custom Attribute innerhtml should be pulled from domain custom attribute name if expanded beyond just VDET. */}
          {inactiveFilterSelectorOptions.has(FilterTypes.CustomAttribute) && <MenuItem value={FilterTypes.CustomAttribute}>Region</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.DayOrBoarding) && <MenuItem value={FilterTypes.DayOrBoarding}>Day or Boarding</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.AcceptsGrade) && <MenuItem value={FilterTypes.AcceptsGrade}>Accepts Grade</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.Subjects) && <MenuItem value={FilterTypes.Subjects}>Subjects</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.Sports) && <MenuItem value={FilterTypes.Sports}>Sports</MenuItem>}
          {inactiveFilterSelectorOptions.has(FilterTypes.HomestayProvider) && <MenuItem value={FilterTypes.HomestayProvider}>Homestay Provider</MenuItem>}
        </Select>
      )}
      {/* TODO: Put this somewhere else once designed */}
      {/* <Box flexGrow={1} /> */}
      {/* {isIframed && <img src="powered-by-enroller.png" alt="Powered by Enroller" />} */}
    </Box>
  );
};
