import { FormControl, FormLabel, Slider, Typography } from '@mui/material';
import { Control, Controller } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';

export interface AgesAcceptedSectionProps {
  control: Control<EditableProfileFields>;
}

function valuetext(value: number) {
  return `${value}`;
}
export const AgesAcceptedSection = ({ control }: AgesAcceptedSectionProps) => {
  return (
    <section id="academic-info-section">
      <Typography variant="body1" sx={{ py: 2, fontWeight: 500 }}>
        Admission Requirements
      </Typography>

      <Controller
        name="agesAccepted"
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControl fullWidth size="small">
            <FormLabel id="averageSatScore-score-input-label" sx={{ mb: 1 }}>
              Ages accepted
            </FormLabel>
            <Slider
              min={4}
              max={20}
              getAriaLabel={() => 'Ages accepted range'}
              value={value}
              onChange={(event, value) => {
                onChange(value);
              }}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
            />
          </FormControl>
        )}
      />
    </section>
  );
};
