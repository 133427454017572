import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from 'components/Loader';
import { ReactElement, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getConfig } from 'utils/config';

interface RequireAuthForRouteProps {
  children: ReactElement;
}

export const RequireAuthForRoute = ({ children }: RequireAuthForRouteProps): ReactElement => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [hasToken, setHasToken] = useState(false);
  const { auth0Audience } = getConfig();

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await getAccessTokenSilently();
        if (token) {
          setHasToken(true);
        }
      } catch {
        loginWithRedirect({
          appState: {
            returnTo: window.location.pathname,
          },
          authorizationParams: {
            audience: auth0Audience,
            prompt: 'login',
            scope: 'openid profile email offline_access read:current_user',
          },
        });
      }
    };

    if (isAuthenticated) {
      fetchToken();
    } else {
      loginWithRedirect({
        appState: {
          returnTo: window.location.pathname,
        },
        authorizationParams: {
          audience: auth0Audience,
          scope: 'openid profile email offline_access read:current_user',
        },
      });
    }
  }, [getAccessTokenSilently, loginWithRedirect, isAuthenticated, auth0Audience]);

  if (isLoading || !hasToken) {
    return <Loader />;
  }

  return isAuthenticated ? children : <Navigate to="/login" />;
};
