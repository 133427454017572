import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { getCountryGrades } from 'utils/countryGradingSystems';

interface AcceptsGradeFilterProps {
  value: number | undefined;
  onChange: (newGrade: number) => void;
  onDiscard: () => void;
}

export const AcceptsGradeFilter = ({ value, onChange, onDiscard }: AcceptsGradeFilterProps) => {
  const gradeOptions = useMemo(
    () =>
      getCountryGrades('United States').map((grade) => (
        <MenuItem key={grade.equivalentAge} value={grade.equivalentAge}>
          {grade.name}
        </MenuItem>
      )),
    [],
  );

  const dropdownValue = value || '';
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Accepts Grade
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth>
        <InputLabel id="grade-filter-select-label">Select grade</InputLabel>
        <Select
          labelId="grade-filter-select-label"
          id="grade-filter-select"
          label="Select grade"
          value={dropdownValue}
          onChange={(event) => onChange(event.target.value as number)}
        >
          {gradeOptions}
        </Select>
      </FormControl>
    </Card>
  );
};
