import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

interface SportsFilterProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  onDiscard: () => void;
}

export const SportsFilter = ({ value, onChange, onDiscard }: SportsFilterProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 400);

  // Params for fetching sport options
  const fetchSportOptionsParams = new URLSearchParams({ search: debouncedSearchTerm });
  const fetchSportsOptionsUrl = `/profiles/filter-options/sports?${fetchSportOptionsParams}`;

  // Setup sport options
  const [sportNames] = useAsync<string[]>(fetchSportsOptionsUrl);
  const [lastOptions, setLastOptions] = useState<string[]>([]);
  useEffect(() => {
    if (sportNames?.length > 0) setLastOptions(sportNames);
  }, [sportNames]);

  // Don't hide options while subsequent options requests are in flight and sportNames is null
  const sportOptions = useMemo(() => sportNames || lastOptions, [sportNames, lastOptions]);

  // Bold the typed partial substring without breaking case or squashing spaces between element boundaries
  const renderPartialBoldOption = (props: HTMLAttributes<HTMLLIElement>, option: string) => {
    const i = option.toLowerCase().indexOf(debouncedSearchTerm);
    const len = debouncedSearchTerm.length;
    if (!len || i === -1) return <li {...props}>{option}</li>;
    const glyphs = [...option]
      .map((char) => (char === ' ' ? <>&nbsp;</> : char))
      .map((glyph: ReactNode, pos) => (pos >= i && pos < i + len ? <strong>{glyph}</strong> : <>{glyph}</>));
    return <li {...props}>{glyphs}</li>;
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Sports
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Autocomplete
        id="sports-filter-autocomplete"
        options={sportOptions}
        multiple
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        onInputChange={(event, newSearchTerm) => setSearchTerm(newSearchTerm)}
        renderInput={(params) => <TextField {...params} label="Search for sport" />}
        renderOption={renderPartialBoldOption}
        sx={{ flex: 1, mb: 1 }}
      />
    </Card>
  );
};
