import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { StudyProgramPeriod } from 'types/study-program-period.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';

interface ProgramPricingFilterProps {
  pricingProgramPeriod: StudyProgramPeriodType;
  pricingMinUsd: StudyProgramPeriod['basePriceUsd'];
  pricingMaxUsd: StudyProgramPeriod['basePriceUsd'];
  onProgramPeriodChange: (newPeriod: StudyProgramPeriodType) => void;
  onMinUsdChange: (newMinUsd: string) => void;
  onMaxUsdChange: (newMaxUsd: string) => void;
  onDiscard: () => void;
}

export const ProgramPricingFilter = ({
  pricingProgramPeriod,
  pricingMinUsd,
  pricingMaxUsd,
  onProgramPeriodChange,
  onMinUsdChange,
  onMaxUsdChange,
  onDiscard,
}: ProgramPricingFilterProps) => {
  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Program Pricing
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
        <FormControl sx={{ width: 300 }}>
          <InputLabel id="program-period-filter-select-label">Select period</InputLabel>
          <Select
            labelId="program-period-filter-select-label"
            id="program-period-filter-select"
            label="Select period"
            value={pricingProgramPeriod}
            onChange={(event) => onProgramPeriodChange(event.target.value as StudyProgramPeriodType)}
          >
            <MenuItem value={StudyProgramPeriodType.CalendarYear}>Calendar year program</MenuItem>
            <MenuItem value={StudyProgramPeriodType.AcademicYear}>Academic year program</MenuItem>
            <MenuItem value={StudyProgramPeriodType.Semester}>Semester program</MenuItem>
            <MenuItem value={StudyProgramPeriodType.ThreeSemester}>Three Semester program</MenuItem>
            <MenuItem value={StudyProgramPeriodType.Term}>Term program</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ width: 150 }}>
          <InputLabel htmlFor="pricing-min-usd-input">From (USD)</InputLabel>
          <OutlinedInput
            id="pricing-min-usd-input"
            value={pricingMinUsd}
            onChange={(event) => onMinUsdChange(event.target.value.replaceAll(/[^\d]/g, ''))}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="From (USD)"
            placeholder="0"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </FormControl>
        <FormControl sx={{ width: 150 }}>
          <InputLabel htmlFor="pricing-max-usd-input">To (USD)</InputLabel>
          <OutlinedInput
            id="pricing-max-usd-input"
            value={pricingMaxUsd}
            onChange={(event) => onMaxUsdChange(event.target.value.replaceAll(/[^\d]/g, ''))}
            startAdornment={<InputAdornment position="start">$</InputAdornment>}
            label="To (USD)"
            placeholder="Any"
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          />
        </FormControl>
      </Stack>
    </Card>
  );
};
