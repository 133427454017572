import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Typography, useTheme } from '@mui/material';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { FeatureCategories } from 'types/features.enum';
import { Feature, ProfileData, SubjectFeature } from 'types/profile.interface';

import { useProfile } from '../../ProfileContext';

export const FeaturesSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;
  const subjects = useRef<SubjectFeature[]>([]);
  const arts = useRef<Feature[]>([]);
  const music = useRef<Feature[]>([]);
  const sports = useRef<Feature[]>([]);
  const extracurricular = useRef<Feature[]>([]);

  useEffect(() => {
    profile.profileFeatures.forEach((pf) => {
      if (pf.feature.category === FeatureCategories.Subjects) subjects.current.push(pf.feature);
      if (pf.feature.category === FeatureCategories.Arts) arts.current.push(pf.feature);
      if (pf.feature.category === FeatureCategories.Music) music.current.push(pf.feature);
      if (pf.feature.category === FeatureCategories.Sports) sports.current.push(pf.feature);
      if (pf.feature.category === FeatureCategories.Extracurricular) extracurricular.current.push(pf.feature);
    });

    const featureLists = [subjects.current, arts.current, music.current, sports.current, extracurricular.current];
    const firstListWithEntries = featureLists.find((list) => list.length > 0) || subjects.current;
    const firstTabIndex = featureLists.indexOf(firstListWithEntries);

    setCurrentTab(Object.values(FeatureCategories)[firstTabIndex].toString() as FeatureCategories);
  }, [profile.profileFeatures]);

  const [currentTab, setCurrentTab] = useState<FeatureCategories>(FeatureCategories.Subjects);
  const handleChange = (event: SyntheticEvent, newValue: FeatureCategories) => {
    setCurrentTab(newValue);
  };

  const featureListBox = (features: (Feature | SubjectFeature)[], featureWidth: string) => {
    if (features.length < 1) return <Typography pl={3}>No data available</Typography>;

    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="start"
        alignContent="start"
        flexWrap="wrap"
        height="100%"
        width="100%"
        py={0}
        pl={3}
        sx={{ overflowY: 'auto' }}
      >
        {features.map((feature: Feature | SubjectFeature) => (
          <Typography key={feature.id} display="inline" textOverflow="wrap" width={featureWidth} mb={1}>
            {feature.name}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Paper style={{ marginBottom: '10px', padding: '20px' }}>
      <Typography variant="h5" color={color}>
        School Features
      </Typography>
      <br />
      <Box sx={{ flexGrow: 1, typography: 'body1', display: 'flex', height: 250 }}>
        <TabContext value={currentTab}>
          <Box sx={{ borderRight: 1, borderColor: 'divider' }}>
            <TabList
              orientation="vertical"
              onChange={handleChange}
              sx={{
                '& button': { background: theme.palette.background.default },
                '& button.Mui-selected': { background: theme.palette.background.paper },
              }}
            >
              <Tab label={FeatureCategories.Subjects} value={FeatureCategories.Subjects} />
              <Tab label={FeatureCategories.Arts} value={FeatureCategories.Arts} />
              <Tab label={FeatureCategories.Music} value={FeatureCategories.Music} />
              <Tab label={FeatureCategories.Sports} value={FeatureCategories.Sports} />
              <Tab label={FeatureCategories.Extracurricular} value={FeatureCategories.Extracurricular} />
            </TabList>
          </Box>
          <TabPanel value={FeatureCategories.Subjects} sx={{ py: 1, px: 2, width: '100%' }}>
            {featureListBox(subjects.current, '50%')}
          </TabPanel>
          <TabPanel value={FeatureCategories.Arts} sx={{ py: 1, px: 2, width: '100%' }}>
            {featureListBox(arts.current, '50%')}
          </TabPanel>
          <TabPanel value={FeatureCategories.Music} sx={{ py: 1, px: 2, width: '100%' }}>
            {featureListBox(music.current, '33%')}
          </TabPanel>
          <TabPanel value={FeatureCategories.Sports} sx={{ py: 1, px: 2, width: '100%' }}>
            {featureListBox(sports.current, '50%')}
          </TabPanel>
          <TabPanel value={FeatureCategories.Extracurricular} sx={{ py: 1, px: 2, width: '100%' }}>
            {featureListBox(extracurricular.current, '50%')}
          </TabPanel>
        </TabContext>
      </Box>
    </Paper>
  );
};
