import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Divider, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Pill } from 'components/Pill';
import { useEffect, useState } from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';

import { EditableProfileFields } from '../get-editable-profile-fields';
import { HiddenSectionHelpPill } from '../HiddenSectionHelpPill';
import { AcademicDetailsSection } from './AcademicDetailsSection';
import { AgesAcceptedSection } from './AgesAcceptedSection';
import { EnglishLanguageSection } from './EnglishLanguageSection';
import { GradesAcceptedSection } from './GradesAcceptedSection';

export interface AcademicInfoProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const AcademicInfoSection = ({ errors, control }: AcademicInfoProps) => {
  const minAcceptedGradeAge = useWatch({ control, name: 'minAcceptedGradeAge' });
  const maxAcceptedGradeAge = useWatch({ control, name: 'maxAcceptedGradeAge' });
  const initIsComplete: boolean = !!(minAcceptedGradeAge && maxAcceptedGradeAge);
  const [isComplete, setIsComplete] = useState<boolean>(initIsComplete);

  useEffect(() => {
    setIsComplete(!!(minAcceptedGradeAge && maxAcceptedGradeAge));
  }, [minAcceptedGradeAge, maxAcceptedGradeAge]);

  return (
    <section id="academic-info-section">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
            Academic info
          </Typography>
          <Controller
            name="showAcademicInfoSection"
            control={control}
            render={({ field: { value, onChange } }) => (
              <>
                <Switch checked={value} onChange={(event, value) => onChange(value)} />
                {!value && <HiddenSectionHelpPill />}
              </>
            )}
          />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Pill
            label={isComplete ? 'Complete' : 'Incomplete'}
            color={isComplete ? 'success' : 'error'}
            icon={isComplete ? <DoneIcon color="success" /> : <WarningAmberIcon color="error" />}
          />
          <Tooltip
            title={
              <>
                <Typography sx={{ fontWeight: 500 }}>This section can be filtered</Typography>
                <Typography>
                  Fill out as many details as possible to appear in filtered searches. More information provided here will allow your school to appear in more
                  searches
                </Typography>
              </>
            }
          >
            <HelpOutlineIcon sx={{ color: grey[500] }} />
          </Tooltip>
        </Stack>
      </Stack>

      {/* TODO: Use <Grid> to stack the stacks vertically instead of horizontally on xs screens */}
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
        <Stack sx={{ flex: 1 }} divider={<Divider flexItem sx={{ my: 3 }} />}>
          <AgesAcceptedSection control={control} />
          <EnglishLanguageSection control={control} errors={errors} />
          <GradesAcceptedSection control={control} errors={errors} />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <AcademicDetailsSection control={control} errors={errors} />
        </Stack>
      </Stack>
    </section>
  );
};
