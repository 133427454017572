import { Typography } from '@mui/material';

import { useFilters } from './FiltersContext';

interface ProfileBrowseTotalProps {
  count: number;
}

export const ProfileBrowseTotal = ({ count }: ProfileBrowseTotalProps) => {
  const {
    state: {
      pagination: { pageSize },
    },
  } = useFilters();

  return (
    <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }}>
      Showing {Math.min(pageSize, count)} of {count} profiles
    </Typography>
  );
};
