import { Chip } from '@mui/material';

import { useFilters } from './FiltersContext';

export const FilterCount = () => {
  const {
    state: { filters },
    updateFilters,
  } = useFilters();

  let appliedFilters: string[] = Object.keys(filters);
  // pricing filter is 3 parameters, remove if they exist and count as 1 for filter reset chip
  if (appliedFilters.includes('pricingProgramPeriod') || appliedFilters.includes('pricingMinUsd') || appliedFilters.includes('pricingMaxUsd')) {
    appliedFilters = appliedFilters.filter((p) => !['pricingProgramPeriod', 'pricingMinUsd', 'pricingMaxUsd'].includes(p));
    appliedFilters.push('pricingProgramPeriod');
  }

  if (appliedFilters.length === 0) {
    return <></>;
  }

  const appliedFiltersLabel = `${appliedFilters.length} filter${appliedFilters.length > 1 ? 's' : ''}`;

  return <Chip clickable onDelete={() => updateFilters({})} label={appliedFiltersLabel} />;
};
