import { createTheme, Theme, useTheme } from '@mui/material';
import { hexToRgb } from 'utils';

export const useThemeOverride = (primaryColorHex: string): Theme => {
  const theme = useTheme();
  const { r: red, g: green, b: blue } = hexToRgb(primaryColorHex)!;
  const main = primaryColorHex;
  const dark = `#${Math.max(red - 64, 0).toString(16)}${Math.max(green - 64, 0).toString(16)}${Math.max(blue - 64, 0).toString(16)}`;
  const light = `#${Math.min(red + 64, 255).toString(16)}${Math.min(green + 64, 255).toString(16)}${Math.min(blue + 64, 255).toString(16)}`;
  return createTheme(theme, {
    palette: {
      primary: {
        main,
        light,
        dark,
        contrastText: red + green + blue > (256 * 3) / 2 ? '#000000' : '#ffffff',
      },
    },
  });
};
