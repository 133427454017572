import { FormControl, FormLabel, MenuItem, Select, Typography } from '@mui/material';
import { useMemo } from 'react';
import { Control, Controller, FieldErrors, useWatch } from 'react-hook-form';
import { getCountryGrades, getCountryGradingSystem } from 'utils/countryGradingSystems';

import { useProfile } from '../../ProfileContext';
import { EditableProfileFields } from '../get-editable-profile-fields';

export interface GradesAcceptedSectionProps {
  control: Control<EditableProfileFields>;
  errors: FieldErrors<EditableProfileFields>;
}

export const GradesAcceptedSection = ({ control, errors }: GradesAcceptedSectionProps) => {
  const {
    state: { profile },
  } = useProfile();
  const country = profile!.country;

  const minAcceptedGradeAge = useWatch({ control, name: 'minAcceptedGradeAge' });
  const gradeDefinition = getCountryGradingSystem(country);
  const defaultGradeName = gradeDefinition.prefix;
  const defaultGradeNameLowercase = defaultGradeName.toLowerCase();
  const gradeOptions = useMemo(
    () =>
      getCountryGrades(country).map((grade) => (
        <MenuItem key={grade.equivalentAge} value={grade.equivalentAge}>
          {grade.name}
        </MenuItem>
      )),
    [country],
  );

  return (
    <section id="grades-accepted-section">
      <Typography variant="h6" sx={{ py: 2, fontWeight: 500 }}>
        {defaultGradeName}s Accepted
      </Typography>
      <Controller
        name="minAcceptedGradeAge"
        control={control}
        rules={{
          required: `Minimum accepted ${defaultGradeNameLowercase} is required`,
        }}
        render={({ field: { value, onChange } }) => (
          <FormControl required sx={{ width: 250 }}>
            <FormLabel id="min-grade-select-label" sx={{ mb: 1 }}>
              From
            </FormLabel>
            <Select aria-labelledby="min-grade-select-label" id="min-grade-select" size="small" value={value} onChange={onChange}>
              {gradeOptions}
            </Select>
            {errors.minAcceptedGradeAge ? <Typography color="error">{errors.minAcceptedGradeAge.message}</Typography> : <br />}
          </FormControl>
        )}
      />
      <Controller
        name="maxAcceptedGradeAge"
        control={control}
        rules={{
          required: `Maximum accepted ${defaultGradeNameLowercase} is required`,
          min: {
            value: minAcceptedGradeAge || 0,
            message: `Maximum accepted ${defaultGradeNameLowercase} must be higher than or equal to minimum accepted ${defaultGradeNameLowercase}`,
          },
        }}
        render={({ field: { value, onChange } }) => (
          <FormControl required sx={{ width: 250, ml: '15px' }}>
            <FormLabel id="max-grade-select-label" sx={{ mb: 1 }}>
              To
            </FormLabel>
            <Select
              aria-labelledby="max-grade-select-label"
              id="max-grade-select"
              size="small"
              value={value}
              onChange={onChange}
              disabled={minAcceptedGradeAge === null}
            >
              {gradeOptions}
            </Select>
            {errors.maxAcceptedGradeAge ? <Typography color="error">{errors.maxAcceptedGradeAge.message}</Typography> : <br />}
          </FormControl>
        )}
      />
    </section>
  );
};
