import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Box, IconButton, Menu, MenuItem, Paper, Stack, Typography } from '@mui/material';
import { useLocalStorage } from 'hooks/use-local-storage';
import { useMemo, useState } from 'react';
import { ProfileData } from 'types/profile.interface';

import { useProfile } from '../../ProfileContext';
import { StudyProgramCard } from './StudyProgramCard';

export const StudyProgramsSection = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const costFormatter = useMemo(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: profile.currency || 'USD',
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 0,
      }),
    [profile.currency],
  );

  const [showCommission, setShowCommission] = useLocalStorage('showPricingCommission', false);

  // Hide the menu button if there is no commission to show, as its toggle is currently the only menu option
  // Do not show this menu to when viewing in shared mode either
  const hasCommission = profile.studyPrograms.some((program) => program.studyProgramPeriods.some((period) => period.commission));
  const showPricingMenuButton = !isSharedView && hasCommission;

  // Track open state of the menu for showing commission
  const [showPricingMenuAnchorEl, setShowPricingMenuAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <Paper style={{ marginBottom: '10px' }}>
      <Box style={{ padding: '20px' }}>
        <Stack direction="row">
          <Typography variant="h5" color={profile.colour} mb="20px" flex={1}>
            Programs and Pricing
          </Typography>
          {showPricingMenuButton && (
            <Box>
              <IconButton
                aria-label="discard"
                id="show-pricing-menu-button"
                aria-controls={showPricingMenuAnchorEl ? 'pricing-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={showPricingMenuAnchorEl ? 'true' : undefined}
                onClick={(event) => setShowPricingMenuAnchorEl(event.currentTarget)}
              >
                <MoreHorizIcon color="secondary" />
              </IconButton>
            </Box>
          )}
          <Menu
            id="pricing-menu"
            anchorEl={showPricingMenuAnchorEl}
            open={showPricingMenuAnchorEl !== null}
            onClose={() => setShowPricingMenuAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'show-pricing-menu-button',
            }}
          >
            <MenuItem onClick={() => setShowCommission(!showCommission)}>{showCommission ? 'Hide' : 'Show'} commission</MenuItem>
          </Menu>
        </Stack>
        {profile.studyPrograms
          .filter((studyProgram) => studyProgram.studyProgramPeriods.length > 0)
          .map((studyProgram) => (
            <StudyProgramCard studyProgram={studyProgram} costFormatter={costFormatter} showCommission={showCommission} />
          ))}
      </Box>
    </Paper>
  );
};
