import { getConfig } from './config';

export type ImageEditOptions = {
  resize?: {
    width?: number;
    height?: number;
    fit?: 'cover' | 'fit';
  };
};

export const getOptimisedImageUrl = (url: string, imageEditOptions?: ImageEditOptions): string => {
  if (!url) {
    return '';
  }

  const { s3Endpoint, s3Bucket, cloudfrontUrl } = getConfig();

  if (!url.includes(`${s3Endpoint}`)) {
    return url;
  }

  // Will use webp when it can, but otherwise will use jpeg
  const defaultEdits = {
    webp: true,
    jpeg: true,
  };
  const fileLocation = url.split(`${s3Endpoint}/`)[1];

  const imageRequest = JSON.stringify({
    bucket: s3Bucket,
    key: fileLocation,
    outputFormat: 'png',
    edits: { ...imageEditOptions, ...defaultEdits },
  });

  return `${cloudfrontUrl}/${btoa(imageRequest)}`;
};
