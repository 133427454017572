import { Box, Paper, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';

import { useProfile } from '../../ProfileContext';

export const TestimonialsSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  return (
    <Paper style={{ padding: 20, marginBottom: '20px' }}>
      <Typography variant="h5" color={color}>
        Testimonials
      </Typography>
      <br />
      {profile.testimonials.map((t) => (
        <Box key={t.name} display="grid" gridTemplateColumns="120px 1fr">
          <img src="/testimonial-icon-grey.png" alt="Quotation marks" style={{ width: '100px', gridColumn: '1' }} />
          <Box gridColumn="2">
            <Typography variant="h6" fontWeight="500">
              {t.name}
            </Typography>
            <Typography m={0}>{t.type}</Typography>
            <Typography>{t.quote}</Typography>
          </Box>
          <br />
        </Box>
      ))}
    </Paper>
  );
};
