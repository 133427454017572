import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FormControl, FormLabel, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Adornment, AdornmentTextField } from 'components/AdornmentTextField';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { stripNonNumerical } from 'utils/stripNonNumerical';

import { EditableProfileFields } from '../get-editable-profile-fields';

export interface EnglishLanguageProps {
  errors: FieldErrors<EditableProfileFields>;
  control: Control<EditableProfileFields>;
}

export const EnglishLanguageSection = ({ errors, control }: EnglishLanguageProps) => {
  return (
    <section id="academic-info-section">
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography variant="body1" sx={{ py: 2, fontWeight: 500 }}>
          English language requirements
        </Typography>
        <Tooltip title="Please add English language requirements to the fields which are applicable for your school">
          <InfoOutlinedIcon fontSize="small" sx={{ color: grey[500] }} />
        </Tooltip>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            name="aeasScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'AEAS score must be higher than 0' },
              max: { value: 100, message: 'AEAS score cannot be higher than 100' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="aeas-score-input-label" sx={{ mb: 1 }}>
                  AEAS minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="aeas-score-input-label"
                  id="aeas-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.aeasScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="100" />,
                  }}
                />
                {errors.aeasScoreMin ? <Typography color="error">{errors.aeasScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="cambridgeScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'Cambridge score must be higher than 0' },
              max: { value: 230, message: 'Cambridge score cannot be higher than 230' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="cambridge-score-input-label" sx={{ mb: 1 }}>
                  Cambridge minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="cambridge-score-input-label"
                  id="cambridge-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.cambridgeScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="230" />,
                  }}
                />
                {errors.cambridgeScoreMin ? <Typography color="error">{errors.cambridgeScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="eltisScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'ELTIS score must be higher than 0' },
              max: { value: 800, message: 'ELTIS score cannot be higher than 800' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="eltis-score-input-label" sx={{ mb: 1 }}>
                  ELTIS minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="eltis-score-input-label"
                  id="eltis-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.eltisScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="800" />,
                  }}
                />
                {errors.eltisScoreMin ? <Typography color="error">{errors.eltisScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="idatScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'IDAT score must be higher than 0' },
              max: { value: 100, message: 'IDAT score cannot be higher than 100' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="idat-score-input-label" sx={{ mb: 1 }}>
                  IDAT minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="idat-score-input-label"
                  id="idat-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.idatScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="%" showSlash={false} />,
                  }}
                />
                {errors.idatScoreMin ? <Typography color="error">{errors.idatScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="ieltsScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'IELTS score must be higher than 0' },
              max: { value: 9, message: 'IELTS score cannot be higher than 9' },
              pattern: { value: /^(?:\d*\.\d{1,3}|\d+)$/, message: 'IELTS score must be a number, with up to 3dp' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="ielts-score-input-label" sx={{ mb: 1 }}>
                  IELTS minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="ielts-score-input-label"
                  id="ielts-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(event.target.value)}
                  size="small"
                  error={Boolean(errors.ieltsScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="9" />,
                  }}
                  inputProps={{ inputMode: 'decimal' }}
                />
                {errors.ieltsScoreMin ? <Typography color="error">{errors.ieltsScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="pearsonScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'Pearson score must be higher than 0' },
              max: { value: 90, message: 'Pearson score cannot be higher than 90' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="pearson-score-input-label" sx={{ mb: 1 }}>
                  Pearson minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="pearson-score-input-label"
                  id="pearson-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.pearsonScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="100" />,
                  }}
                />
                {errors.pearsonScoreMin ? <Typography color="error">{errors.pearsonScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="toeflScoreMin"
            control={control}
            rules={{
              min: { value: 0, message: 'TOEFL score must be higher than 0' },
              max: { value: 120, message: 'TOEFL score cannot be higher than 120' },
            }}
            render={({ field: { value, onChange } }) => (
              <FormControl fullWidth size="small">
                <FormLabel id="toefl-score-input-label" sx={{ mb: 1 }}>
                  TOEFL minimum score
                </FormLabel>
                <AdornmentTextField
                  fullWidth
                  aria-labelledby="toefl-score-input-label"
                  id="toefl-score-input"
                  value={value ?? ''}
                  onChange={(event) => onChange(stripNonNumerical(event.target.value))}
                  size="small"
                  error={Boolean(errors.toeflScoreMin)}
                  InputProps={{
                    endAdornment: <Adornment maxRange="100" />,
                  }}
                />
                {errors.toeflScoreMin ? <Typography color="error">{errors.toeflScoreMin.message}</Typography> : <br />}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </section>
  );
};
