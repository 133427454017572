import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { getConfig } from 'utils/config';

export type AsyncStatus = 'idle' | 'pending' | 'success' | 'error';

export type AsyncReturn<T> = [res: T, status: AsyncStatus, execute: (data?: any) => Promise<void>, error: AxiosError | null];

export const useAsync = <T = any>(url = '', method = 'GET', immediate = true, configs: Partial<AxiosRequestConfig> = {}): AsyncReturn<T> => {
  const { getAccessTokenSilently } = useAuth0();
  const [status, setStatus] = useState<AsyncStatus>('idle');
  const [res, setRes] = useState<any>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [exeID, setExeID] = useState<number | undefined>(undefined);
  const { enqueueSnackbar } = useSnackbar();
  const { apiUrl } = getConfig();
  const configsTransformed = JSON.stringify(configs);

  const execute = useCallback(
    async (data = null) => {
      setStatus('pending');
      setRes(null);
      setError(null);
      setExeID(Date.now());

      const { headers, ...restCfgs } = JSON.parse(configsTransformed);
      const accessToken = await getAccessTokenSilently();

      const config = {
        method,
        url: `${apiUrl}/api${url}`,
        data,
        headers: { Authorization: `Bearer ${accessToken}`, ...headers },
        ...restCfgs,
      } as AxiosRequestConfig;

      try {
        const response = await axios(config);
        setRes(response.data);
        setStatus('success');
      } catch (error: any) {
        setError(error);
        setStatus('error');
      }
    },
    [apiUrl, url, method, configsTransformed, getAccessTokenSilently],
  );

  useEffect(() => {
    if (status === 'error' && error) {
      if (error.response?.status === 401 || error.response?.status === 403) {
        setError(null);
        getAccessTokenSilently();
      } else {
        enqueueSnackbar((error.response?.data as any)?.message || error.message, { variant: 'error', key: exeID, preventDuplicate: true });
        setError(null);
      }
    } else if (status === 'success' && res.message && method !== 'GET') {
      enqueueSnackbar(res.message, { variant: 'success', key: exeID, preventDuplicate: true });
    }
  }, [getAccessTokenSilently, status, error, enqueueSnackbar, res, method, exeID]);

  useEffect(() => {
    if (immediate) execute();
  }, [execute, immediate]);

  return [res, status, execute, error];
};
