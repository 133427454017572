import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { useFilters } from './FiltersContext';

export const ProfileSearch = () => {
  const {
    state: { search },
    updateSearch,
  } = useFilters();
  const [value, setValue] = useState(search);
  const [debouncedSearchTerm] = useDebounce<string>(value, 400);

  useEffect(() => {
    updateSearch(debouncedSearchTerm);
  }, [updateSearch, debouncedSearchTerm]);

  return (
    <TextField
      sx={{ minWidth: '30%' }}
      size="small"
      placeholder="Search profiles"
      variant="outlined"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton sx={{ visibility: search ? 'visible' : 'hidden' }} onClick={() => setValue('')}>
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  );
};
