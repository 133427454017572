import { Paper, Typography, useTheme } from '@mui/material';
import { StudyProgram } from 'types/study-program.interface';
import { StudyProgramPeriodType } from 'types/study-program-period-type.enum';

import { StudyProgramPeriodBlock } from './StudyProgramPeriodBlock';

export interface StudyProgramProps {
  studyProgram: StudyProgram;
  costFormatter: Intl.NumberFormat;
  showCommission: boolean;
}

export const StudyProgramCard = ({ studyProgram, costFormatter, showCommission }: StudyProgramProps) => {
  const theme = useTheme();
  const backgroundColor = theme.palette.background.default;

  const programPeriodNames = {
    [StudyProgramPeriodType.AcademicYear]: 'Academic Year',
    [StudyProgramPeriodType.CalendarYear]: 'Calendar Year',
    [StudyProgramPeriodType.Semester]: 'Semester',
    [StudyProgramPeriodType.ThreeSemester]: 'Three Semester',
    [StudyProgramPeriodType.Term]: 'Term',
  };
  const firstCostType = studyProgram.studyProgramPeriods?.[0]?.costType;
  const commissionTypeText = firstCostType === 'net' ? '(Net)' : '(Gross)';
  const fallbackProgramName = `${programPeriodNames[studyProgram.periodType]} Program ${firstCostType ? commissionTypeText : ''}`;

  return (
    <Paper key={studyProgram.id} sx={{ padding: '20px', marginBottom: '10px', backgroundColor }}>
      <Typography variant="h6" fontWeight="600" mb={1}>
        {studyProgram.name || fallbackProgramName}
      </Typography>
      {studyProgram.studyProgramPeriods.map((studyProgramPeriod) => (
        <StudyProgramPeriodBlock studyProgramPeriod={studyProgramPeriod} costFormatter={costFormatter} showCommission={showCommission} />
      ))}
    </Paper>
  );
};
