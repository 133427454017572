import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography } from '@mui/material';

import { FilterBoolean } from '../filter-boolean.type';

interface PublicOrPrivateFilterProps {
  value: FilterBoolean;
  onChange: (isPrivate: FilterBoolean) => void;
  onDiscard: () => void;
}

export const PublicOrPrivateFilter = ({ value, onChange, onDiscard }: PublicOrPrivateFilterProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange((event.target as HTMLInputElement).value as FilterBoolean);
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Public or private school
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <FormControl fullWidth>
        <RadioGroup value={value} onChange={handleChange} row name="public-or-private-filter-radio-group">
          <FormControlLabel value="false" control={<Radio />} label="Public" />
          <FormControlLabel value="true" control={<Radio />} label="Private" />
        </RadioGroup>
      </FormControl>
    </Card>
  );
};
