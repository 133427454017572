import { Box, Paper, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { getCountryGrades, getCountryGradingSystem } from 'utils/countryGradingSystems';

import { useProfile } from '../../ProfileContext';

export const AcademicSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  const displayCurriculums = profile.curriculums.length > 0;
  const curriculums = profile.curriculums.map((c) => c.name).join(', ');

  const hasAgeRange = profile.agesAcceptedMin || profile.agesAcceptedMax;
  const ageRange = `${profile.agesAcceptedMin || 'Any'}-${profile.agesAcceptedMax || 'Any'}`;

  const gradingSystem = getCountryGradingSystem(profile.country);
  const grades = getCountryGrades(profile.country);
  const minGrade = grades.find((grade) => grade.equivalentAge === profile.minAcceptedGradeAge);
  const maxGrade = grades.find((grade) => grade.equivalentAge === profile.maxAcceptedGradeAge);
  const hasGrades = minGrade && maxGrade;
  const gradesAccepted = `${minGrade?.name} to ${maxGrade?.name}`;

  return (
    <Paper sx={{ marginBottom: '10px' }}>
      <Box style={{ padding: '20px' }}>
        <Typography variant="h5" mb={2} color={color}>
          Academic
        </Typography>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography p={0.5} fontWeight="600">
              Admission requirements
            </Typography>
            {hasAgeRange && (
              <Typography p={0.5}>
                Ages accepted: <strong>{ageRange}</strong>
              </Typography>
            )}
            {hasGrades && (
              <Typography p={0.5}>
                Accepting {gradingSystem.prefix.toLowerCase()}: <strong>{gradesAccepted}</strong>
              </Typography>
            )}
            {profile.aeasScoreMin && (
              <Typography p={0.5}>
                AEAS: <strong>{profile.aeasScoreMin}</strong>
              </Typography>
            )}
            {profile.cambridgeScoreMin && (
              <Typography p={0.5}>
                Cambridge: <strong>{profile.cambridgeScoreMin}</strong>
              </Typography>
            )}
            {profile.eltisScoreMin && (
              <Typography p={0.5}>
                ELTIS: <strong>{profile.eltisScoreMin}</strong>
              </Typography>
            )}
            {profile.idatScoreMin && (
              <Typography p={0.5}>
                IDAT: <strong>{profile.idatScoreMin}</strong>
              </Typography>
            )}
            {profile.ieltsScoreMin && (
              <Typography p={0.5}>
                IELTS: <strong>{profile.ieltsScoreMin}</strong>
              </Typography>
            )}
            {profile.pearsonScoreMin && (
              <Typography p={0.5}>
                Pearson: <strong>{profile.pearsonScoreMin}</strong>
              </Typography>
            )}
            {profile.toeflScoreMin && (
              <Typography p={0.5}>
                TOEFL: <strong>{profile.toeflScoreMin}</strong>
              </Typography>
            )}
          </Box>
          <Box>
            <Typography p={0.5} fontWeight="600">
              Academic stats
            </Typography>
            <Typography p={0.5}>
              Diploma year offered: <strong>{profile.usOneYearDiploma ? 'Yes' : 'No'}</strong>
            </Typography>
            {profile.averageSatScore && (
              <Typography p={0.5}>
                Average SAT score: <strong>{profile.averageSatScore}</strong>
              </Typography>
            )}
            <Typography p={0.5}>
              English as Second Language (ESL) support: <strong>{profile.englishLangSupport ? 'Yes' : 'No'}</strong>
            </Typography>
            {displayCurriculums && (
              <Typography p={0.5}>
                Curriculum offered: <strong>{curriculums}</strong>
              </Typography>
            )}
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </Paper>
  );
};
