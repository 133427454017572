import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import packageJson from '../../package.json';
import { AuthUser } from '../types/user.interface';
import { getConfig } from './config';
import { getUserId } from './user';

export const initSentry = () => {
  const { sentryDsn, environment, apiUrl } = getConfig();

  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing({ tracePropagationTargets: [apiUrl] })],
    tracesSampleRate: 1.0,
    environment,
    release: packageJson.version,
  });
};

export const setSentryUser = (user: AuthUser) => {
  Sentry.setUser({
    username: `${user.userData.firstName} ${user.userData.lastName}`,
    email: user.email,
    id: getUserId(user),
  });
};
