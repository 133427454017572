import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItem, Paper, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { IFile } from 'types/file.interface';
import { FileRoles } from 'types/file-roles.enum';
import { ProfileData } from 'types/profile.interface';

import { useProfile } from '../../ProfileContext';
import { FileTable } from './FileTable';

export const DocumentsSection = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  const sortDocuments = (a: IFile, b: IFile) => (a.filename.toLowerCase() > b.filename.toLowerCase() ? 1 : -1);

  const files = profile.files || [];
  const prospectus: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_PROSPECTUS).sort(sortDocuments);
  const marketingMaterials: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_MARKETING_MATERIALS).sort(sortDocuments);
  const contracts: IFile[] = files.filter((file) => file.role === FileRoles.FILE_ROLE_CONTRACTS_AGREEMENTS).sort(sortDocuments);

  const [isMarketingMaterialsCollapsed, setIsMarketingMaterialsCollapsed] = useState<boolean>(true);
  const [isProspectusCollapsed, setIsProspectusCollapsed] = useState<boolean>(true);
  const [isContractsCollapsed, setIsContractsCollapsed] = useState<boolean>(true);

  const shouldRenderMarketingMaterials = marketingMaterials.length >= 1;
  const shouldRenderProspectus = prospectus.length >= 1;
  const shouldRenderContracts = !isSharedView && contracts.length >= 1;

  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h5" color={color}>
        Documents
      </Typography>
      <List>
        {shouldRenderMarketingMaterials && (
          <Box>
            <ListItem onClick={() => setIsMarketingMaterialsCollapsed(!isMarketingMaterialsCollapsed)} button>
              <Typography variant="h6">Marketing Materials ({marketingMaterials.length})</Typography>&nbsp;
              {isMarketingMaterialsCollapsed ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
            <Collapse in={!isMarketingMaterialsCollapsed} timeout="auto">
              <FileTable files={marketingMaterials} profileSlug={profile.slug}></FileTable>
            </Collapse>
          </Box>
        )}

        {shouldRenderProspectus && (
          <Box>
            <ListItem onClick={() => setIsProspectusCollapsed(!isProspectusCollapsed)} button>
              <Typography variant="h6">Prospectus ({prospectus.length})</Typography>&nbsp;
              {isProspectusCollapsed ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
            <Collapse in={!isProspectusCollapsed} timeout="auto">
              <FileTable files={prospectus} profileSlug={profile.slug}></FileTable>
            </Collapse>
          </Box>
        )}

        {shouldRenderContracts && (
          <Box>
            <ListItem onClick={() => setIsContractsCollapsed(!isContractsCollapsed)} button>
              <Typography variant="h6">Contracts &amp; Agreements ({contracts.length})</Typography>&nbsp;
              {isContractsCollapsed ? <ExpandMore /> : <ExpandLess />}
            </ListItem>
            <Collapse in={!isContractsCollapsed} timeout="auto">
              <FileTable files={contracts} profileSlug={profile.slug}></FileTable>
            </Collapse>
          </Box>
        )}
      </List>
    </Paper>
  );
};
