import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Card, IconButton, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useMemo } from 'react';
import { ProfileData } from 'types/profile.interface';

interface CustomAttributeFilterProps {
  value: ProfileData['customAttribute'];
  onChange: (newCustomAttrib: ProfileData['customAttribute']) => void;
  onDiscard: () => void;
}

export const CustomAttributeFilter = ({ value, onChange, onDiscard }: CustomAttributeFilterProps) => {
  const fetchCustomAttributeOptionsUrl = `/profiles/filter-options/custom-attributes`;
  const [customAttribs, customAttribsStatus] = useAsync<string[]>(fetchCustomAttributeOptionsUrl);
  const customAttribOptions = useMemo(() => customAttribs?.map((label) => ({ label })) || [], [customAttribs]);

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          {/* TODO: Custom Attribute name should be pulled from domain custom attribute name if expanded beyond just VDET. */}
          Region
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Autocomplete
        id="custom-attribute-autocomplete"
        options={customAttribOptions}
        isOptionEqualToValue={(opt, val) => opt.label === val.label}
        value={{ label: value }}
        onChange={(event, value) => onChange(value?.label || '')}
        renderInput={(params) => (customAttribsStatus === 'success' ? <TextField {...params} label="Region" /> : <Skeleton animation="wave" />)}
        sx={{ flex: 1 }}
      />
    </Card>
  );
};
