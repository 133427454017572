import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import SearchIcon from '@mui/icons-material/Search';
import { Avatar, Box, Button, Grid, MenuItem, Pagination, Select, Stack, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useEffect, useMemo } from 'react';
import { Country } from 'types/country.interface';
import { PaginatedResponseEnvelope } from 'types/paginated-response-envelope.interface';
import { ProfileSummary } from 'types/profile.interface';

import { useFilters } from './FiltersContext';
import { ProfileCard } from './ProfileCard';

interface BrowseProfilesProps {
  onProfilesLoad: (profiles: PaginatedResponseEnvelope<ProfileSummary>) => void;
  openFilterDrawer: () => void;
  filteredProfilesCount: number;
}

export const BrowseProfiles = ({ onProfilesLoad, openFilterDrawer, filteredProfilesCount }: BrowseProfilesProps) => {
  const {
    state: { filters: filterParams, search, pagination },
    updatePage,
    updatePagination,
  } = useFilters();
  const [countries] = useAsync<Country[]>('/countries');
  const countryFlagSrcMap = useMemo(
    () =>
      countries?.reduce((acc, country) => {
        acc.set(country.name, `/flag-icons/${country.iso_3166_3}.svg`);
        return acc;
      }, new Map<string, string>()),
    [countries],
  );

  // Query string parameters for profile search
  const profileSearchParams = new URLSearchParams();

  // Pagination params
  profileSearchParams.append('page', pagination.page.toString());
  profileSearchParams.append('pageSize', pagination.pageSize.toString());

  // Profile Filter params
  if (search) profileSearchParams.set('search', search.trim());
  if (filterParams.coedStatus) profileSearchParams.append('coedStatus[]', filterParams.coedStatus);
  if (filterParams.country) profileSearchParams.append('country[]', filterParams.country);
  if (filterParams.state) profileSearchParams.append('state[]', filterParams.state);
  if (filterParams.city) profileSearchParams.append('city[]', filterParams.city);
  if (filterParams.customAttribute) profileSearchParams.append('customAttribute[]', filterParams.customAttribute);
  if (filterParams.programAvailability) profileSearchParams.append('programAvailability', filterParams.programAvailability);
  if (filterParams.pricingProgramPeriod) profileSearchParams.append('pricingProgramPeriod', filterParams.pricingProgramPeriod);
  if (filterParams.pricingMinUsd) profileSearchParams.append('pricingMinUsd', filterParams.pricingMinUsd);
  if (filterParams.pricingMaxUsd) profileSearchParams.append('pricingMaxUsd', filterParams.pricingMaxUsd);
  if (filterParams.isPrivateSchool) profileSearchParams.append('isPrivateSchool', filterParams.isPrivateSchool);
  if (filterParams.hasEsolSupport) profileSearchParams.append('hasEsolSupport', filterParams.hasEsolSupport);
  if (filterParams.hasUsDiploma) profileSearchParams.append('hasUsDiploma', filterParams.hasUsDiploma);
  if (filterParams.acceptsAge) profileSearchParams.append('acceptsAge', filterParams.acceptsAge.toString());
  if (filterParams.dayOrBoarding) profileSearchParams.append('dayOrBoarding', filterParams.dayOrBoarding);
  if (filterParams.acceptsGrade) profileSearchParams.append('acceptsGrade', filterParams.acceptsGrade.toString());
  if (filterParams.subjects) filterParams.subjects.forEach((subject: string) => profileSearchParams.append('subjects[]', subject));
  if (filterParams.sports) filterParams.sports.forEach((subject: string) => profileSearchParams.append('sports[]', subject));
  if (filterParams.homestayProvider) profileSearchParams.append('homestayProvider', filterParams.homestayProvider);

  // Sorting params
  profileSearchParams.append('order', pagination.order);
  profileSearchParams.append('orderDirection', pagination.orderDirection);

  // Load the actual profiles we'll show with user-defined filtering and pagination
  const getFilteredProfilesUrl = `/profiles?${profileSearchParams.toString()}`;
  const [getFilteredProfilesResult, getFilteredProfilesStatus] = useAsync<PaginatedResponseEnvelope<ProfileSummary>>(getFilteredProfilesUrl);

  useEffect(() => {
    if (getFilteredProfilesStatus === 'success') onProfilesLoad(getFilteredProfilesResult);
  }, [getFilteredProfilesStatus, getFilteredProfilesResult, onProfilesLoad]);

  const onPageSizeChange = (pageSize: number) => {
    updatePagination({ ...pagination, pageSize });
  };

  const filteredProfiles = getFilteredProfilesResult?.data || Array(pagination.pageSize).fill(null);

  return (
    <Box sx={{ px: 4, py: 2 }}>
      <Grid container spacing={5} alignItems="stretch" sx={{ pt: 3 }}>
        {filteredProfiles.map((profile, i) => (
          <ProfileCard key={profile?.id || i} filterParams={filterParams} profile={profile} flagSrc={countryFlagSrcMap?.get(profile?.country) || null} />
        ))}
      </Grid>
      {filteredProfiles.length < 1 && (
        <Stack direction="column" alignItems="center" height="90%" pt="5vh">
          <Avatar sx={{ mb: 2, backgroundColor: 'primary.light', height: '72px', width: '72px' }}>
            <SearchIcon sx={{ color: 'primary.dark', height: '48px', width: '48px' }} />
          </Avatar>
          <Typography variant="h6" fontWeight="500" mb={1}>
            No schools found
          </Typography>
          <Typography mb={2} maxWidth={400} textAlign="center" color="secondary">
            The filters used did not match any schools. Please change filter criteria.
          </Typography>
          <Button
            variant="contained"
            endIcon={<Icon path={mdiFilter} size={1} />}
            sx={{
              mr: 1,
              backgroundColor: 'primary.light',
              color: 'primary.dark',
              fontSize: 20,
              '&:hover': {
                backgroundColor: 'primary.light',
              },
            }}
            onClick={openFilterDrawer}
          >
            Open filters
          </Button>
        </Stack>
      )}

      <Box sx={{ mt: 5, mb: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Select
          value={pagination.pageSize}
          SelectDisplayProps={{ style: { padding: '5px 30px 5px 10px' } }}
          onChange={(e) => onPageSizeChange(e.target.value as number)}
        >
          <MenuItem value={12}>12</MenuItem>
          <MenuItem value={24}>24</MenuItem>
          <MenuItem value={36}>36</MenuItem>
          <MenuItem value={60}>60</MenuItem>
        </Select>

        <Pagination
          count={Math.ceil(filteredProfilesCount / pagination.pageSize)}
          sx={{ zIndex: 500 }}
          page={pagination.page}
          onChange={(_, v) => updatePage(v)}
        />
      </Box>
    </Box>
  );
};
