import { useAuth0 } from '@auth0/auth0-react';
import { mdiFilter } from '@mdi/js';
import Icon from '@mdi/react';
import { Box, Button, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import { Footer } from 'components/Footer';
import { useIsIframed } from 'hooks/use-is-iframed';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { PaginatedResponseEnvelope } from 'types/paginated-response-envelope.interface';
import { ProfileSummary } from 'types/profile.interface';
import { AuthUser } from 'types/user.interface';

import { BrowseProfiles } from './BrowseProfiles';
import { CommissionCSVDownloadButton } from './CommissionCSVDownloadButton';
import { FilterCount } from './FilterCount';
import { FiltersSelectorPanel } from './filtering/FiltersSelectorPanel';
import { FiltersProvider } from './FiltersContext';
import { ProfileBrowseTotal } from './ProfileBrowseTotal';
import { ProfileSearch } from './ProfileSearch';
import { ProfileSort } from './ProfileSort';

export const ProviderList = () => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState<boolean>(false);
  const [filteredProfilesCount, setFilteredProfilesCount] = useState<number>(0);
  const filterDrawerWidth = 640;
  const toggleFilterDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
      return;
    }
    setIsFilterDrawerOpen(!isFilterDrawerOpen);
  };

  const theme = useTheme();
  const isIframed = useIsIframed();
  const { user } = useAuth0<AuthUser>();

  const isEnrollerAdmin = user!.userData.roles.includes('enroller-admin');

  const headerRef = useRef<HTMLElement>();
  const [headerHeight, setHeaderHeight] = useState(0);
  const appBarHeight = parseInt(theme.spacing(7).replace('px', ''));

  useLayoutEffect(() => {
    if (headerRef.current) {
      setHeaderHeight((isIframed ? 0 : appBarHeight) + headerRef.current.clientHeight);
    }
  }, [headerRef, isIframed, appBarHeight]);

  const onProfilesLoad = (profiles: PaginatedResponseEnvelope<ProfileSummary>) => {
    setFilteredProfilesCount(profiles.total || 0);
  };

  return (
    <FiltersProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box ref={headerRef} sx={{ px: 4, py: 3, backgroundColor: 'background.paper' }}>
          <Typography display="inline" variant="h6" fontWeight="600" sx={theme.mixins.titleGradient}>
            Marketplace
          </Typography>
          <br />
          <Box display="flex" flexDirection="row" alignItems="center">
            <ProfileSearch />
            <ProfileBrowseTotal count={filteredProfilesCount} />
            <FilterCount />
            <Box flex={1} />
            {isEnrollerAdmin && <CommissionCSVDownloadButton />}
            <ProfileSort />
            <Button
              endIcon={<Icon path={mdiFilter} size={1} />}
              color={isFilterDrawerOpen ? 'inherit' : 'secondary'}
              variant={isFilterDrawerOpen ? 'contained' : 'outlined'}
              sx={{
                color: isFilterDrawerOpen ? 'primary.dark' : 'secondary',
                backgroundColor: isFilterDrawerOpen ? 'primary.light' : 'secondary',
                '&:hover': {
                  backgroundColor: isFilterDrawerOpen ? 'primary.light' : 'inherit',
                },
                mr: 1,
              }}
              onClick={toggleFilterDrawer}
            >
              Filters
            </Button>
            <SwipeableDrawer
              disableEnforceFocus
              anchor="right"
              open={isFilterDrawerOpen}
              onClose={toggleFilterDrawer}
              onOpen={toggleFilterDrawer}
              keepMounted
              sx={{
                marginLeft: 'auto',
                width: filterDrawerWidth,
                top: headerHeight,
                '& .MuiBackdrop-root': {
                  display: 'none',
                },
                '& .MuiDrawer-paper': {
                  width: filterDrawerWidth,
                  position: 'absolute',
                },
              }}
              disableSwipeToOpen={typeof navigator !== 'undefined' && /iPad|iPhone|iPod|Windows/.test(navigator.userAgent)}
            >
              <FiltersSelectorPanel onClose={() => setIsFilterDrawerOpen(false)} />
            </SwipeableDrawer>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" height="100%" sx={{ overflowY: 'scroll' }}>
          <BrowseProfiles onProfilesLoad={onProfilesLoad} openFilterDrawer={() => setIsFilterDrawerOpen(true)} filteredProfilesCount={filteredProfilesCount} />
          <Box flex="1" />
          {!isIframed && <Footer />}
        </Box>
      </Box>
    </FiltersProvider>
  );
};
