import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, FormControl, IconButton, Modal, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IFile } from 'types/file.interface';
import { capitaliseFirst } from 'utils/capitalise-first';

import { FileUploadDropzone } from '../FilesUploadDropzone';
import { FileUpload } from '../FileUpload';

interface UploadDocumentsModalProps {
  isOpen: boolean;
  fileRole: string;
  onClose: (newFiles: IFile[]) => void;
}

export const UploadDocumentsModal = ({ isOpen, fileRole, onClose }: UploadDocumentsModalProps) => {
  const { profileSlug } = useParams();
  const [uploadingFiles, setUploadingFiles] = useState<File[][]>([]);
  const [doneFiles, setDoneFiles] = useState<IFile[]>([]);
  const [uploadingCount, setUploadingCount] = useState<number>(0);

  const deleteManyFilesUrl = `/profiles/${profileSlug}/files?ids=${doneFiles.map((f) => f.id).join(',')}`;
  const [, deleteManyStatus, deleteUploadedFiles] = useAsync(deleteManyFilesUrl, 'DELETE', false);

  const theme = useTheme();

  const documentRoleName = fileRole.replaceAll('-', ' ');

  const onDone = () => {
    onClose(doneFiles);
    setDoneFiles([]);
    setUploadingFiles([]);
  };

  const discardAndClose = () => {
    if (uploadingFiles.length) {
      deleteUploadedFiles();
      setUploadingFiles([]);
      setDoneFiles([]);
    }
    onClose([]);
  };

  const onUploadStart = useCallback((newFiles: File[]) => {
    setUploadingCount((i) => i + 1);
    setUploadingFiles((files) => [...files, newFiles]);
  }, []);

  const onUploadComplete = useCallback((newFiles: IFile[]) => {
    setUploadingCount((i) => i - 1);
    setDoneFiles((files) => files.concat(newFiles));
  }, []);

  return (
    <>
      {isOpen && <Box position="absolute" left={0} top={0} minWidth="100vw" minHeight="100vh" sx={{ backdropFilter: 'blur(7px)', zIndex: 999 }} />}
      <Modal open={isOpen} aria-labelledby="upload-documents-modal-title" aria-describedby="upload-documents-modal-description">
        <Box sx={theme.mixins.modalStyle}>
          <Stack flex={1}>
            <Stack direction="row" alignItems="center">
              <Typography id="upload-documents-modal-title" variant="h6" component="h2">
                Add {documentRoleName}
              </Typography>
              <Box flex={1} />
              <Tooltip title="Discard">
                <span>
                  <IconButton aria-label="discard" disabled={uploadingCount > 0 || deleteManyStatus === 'pending'} onClick={discardAndClose}>
                    {deleteManyStatus === 'pending' ? <CircularProgress size="24px" /> : <CloseIcon color={uploadingCount > 0 ? 'disabled' : 'error'} />}
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
            <Box minHeight={0} overflow="auto" flex={1}>
              <Typography id="upload-documents-modal-description" sx={{ mb: 3, mt: 2 }} color="secondary" variant="body2">
                Upload PDF documents to be shown in the '{capitaliseFirst(documentRoleName)}' section of your documents
              </Typography>
              <FileUploadDropzone onUploadStart={onUploadStart} maxFiles={100} mimeTypes={['application/pdf']} />
              {uploadingFiles.map((upload, index) => {
                return <FileUpload fileRole={fileRole} files={upload} onUploadComplete={onUploadComplete} key={index} />;
              })}
            </Box>
            <FormControl fullWidth>
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  fullWidth
                  disabled={uploadingCount > 0}
                  loading={deleteManyStatus === 'pending'}
                  variant="outlined"
                  onClick={discardAndClose}
                  color="error"
                  endIcon={<DeleteOutlinedIcon />}
                >
                  Discard
                </LoadingButton>
                <Button fullWidth variant="contained" disabled={uploadingCount > 0} onClick={onDone} color="primary" endIcon={<TaskAltIcon />}>
                  Done
                </Button>
              </Stack>
            </FormControl>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
