import { Point } from 'geojson';
import mapboxgl from 'mapbox-gl';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { createGeoJSONCircle } from 'utils/create-geojson-circle';

/**
 * Workaround for this issue in production builds
 * @see https://github.com/mapbox/mapbox-gl-js/issues/10173
 */
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

interface MapWithRadiusProps {
  center: Point;
  radiusKm: number;
  zoom: number;
  height: CSSProperties['height'];
  width: CSSProperties['width'];
}

export const MapWithRadius: React.FC<MapWithRadiusProps> = ({ center, radiusKm, zoom, width, height }) => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState<mapboxgl.Map | null>(null);
  const [marker, setMarker] = useState<mapboxgl.Marker | null>(null);
  const [markerPos, setMarkerPos] = useState<string>('');

  useEffect(() => {
    const [lng, lat] = center.coordinates;
    const initializeMap = ({ setMap, mapContainer }: any) => {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v12',
        center: [lng, lat],
        zoom,
      });
      map.on('load', () => {
        setMap(map);
        map.resize();
        addMarker(map);
        addRadius(map);
      });
      // Disable panning and scroll zoom
      map.dragPan.disable();
      map.keyboard.disable();
      map.scrollZoom.disable();
      map.touchZoomRotate.disable();
      map.doubleClickZoom.disable();
      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, 'top-right');
    };

    const addMarker = (map: mapboxgl.Map) => {
      const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
      setMarker(marker);
      setMarkerPos(`${lat},${lng}`);
    };
    const addRadius = (map: mapboxgl.Map) => {
      const geometry = createGeoJSONCircle(center, radiusKm);
      map.addSource('radius', geometry as mapboxgl.AnySourceData);
      map.addLayer({
        id: 'polygon',
        type: 'fill',
        source: 'radius',
        layout: {},
        paint: {
          'fill-color': 'green',
          'fill-opacity': 0.15,
        },
      });
    };
    if (map && marker && markerPos !== `${lat},${lng}`) {
      marker.remove();
      addMarker(map);
      addRadius(map);
    }

    if (!map) {
      initializeMap({ setMap, mapContainer });
    }
  }, [map, center, radiusKm, zoom, marker, markerPos]);

  return <div className="mapboxgl-container" ref={mapContainer} style={{ height, width }} />;
};
