import DeleteIcon from '@mui/icons-material/Delete';
import { Autocomplete, Box, Card, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useAsync } from 'hooks/use-async';
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';

interface SubjectsFilterProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  onDiscard: () => void;
}

export const SubjectsFilter = ({ value, onChange, onDiscard }: SubjectsFilterProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm] = useDebounce<string>(searchTerm, 400);

  // Params for fetching subject options
  const fetchSubjectOptionsParams = new URLSearchParams({ search: debouncedSearchTerm });
  const fetchSubjectsOptionsUrl = `/profiles/filter-options/subjects?${fetchSubjectOptionsParams}`;

  // Setup subject options
  const [subjectNames] = useAsync<string[]>(fetchSubjectsOptionsUrl);
  const [lastOptions, setLastOptions] = useState<string[]>([]);
  useEffect(() => {
    if (subjectNames?.length > 0) setLastOptions(subjectNames);
  }, [subjectNames]);

  // Don't hide options while subsequent options requests are in flight and subjectNames is null
  const subjectOptions = useMemo(() => subjectNames || lastOptions, [subjectNames, lastOptions]);

  // Bold the typed partial substring without breaking case or squashing spaces between element boundaries
  const renderPartialBoldOption = (props: HTMLAttributes<HTMLLIElement>, option: string) => {
    const i = option.toLowerCase().indexOf(debouncedSearchTerm);
    const len = debouncedSearchTerm.length;
    if (!len || i === -1) return <li {...props}>{option}</li>;
    const glyphs = [...option]
      .map((char) => (char === ' ' ? <>&nbsp;</> : char))
      .map((glyph: ReactNode, pos) => (pos >= i && pos < i + len ? <strong>{glyph}</strong> : <>{glyph}</>));
    return <li {...props}>{glyphs}</li>;
  };

  return (
    <Card sx={{ p: 2, mb: 2 }}>
      <Stack direction="row">
        <Typography variant="h6" mb={2}>
          Subjects
        </Typography>
        <Box flex={1} />
        <IconButton aria-label="discard" onClick={onDiscard}>
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Autocomplete
        id="subjects-filter-autocomplete"
        options={subjectOptions}
        multiple
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => onChange(newValue)}
        onInputChange={(event, newSearchTerms) => setSearchTerm(newSearchTerms)}
        renderInput={(params) => <TextField {...params} label="Search for subject" />}
        renderOption={renderPartialBoldOption}
        sx={{ flex: 1, mb: 1 }}
      />
    </Card>
  );
};
