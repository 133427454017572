import { useAuth0 } from '@auth0/auth0-react';
import { Typography } from '@mui/material';
import { Loader } from 'components/Loader';
import { Navigate, useParams } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { canUserEditMarketingProfile } from 'utils/userRoles';

import { useProfile } from '../ProfileContext';
import { EditProfileForm } from './EditProfileForm';

export const Edit = () => {
  const { user } = useAuth0<AuthUser>();
  const { profileSlug } = useParams();
  const {
    state: { profile, status },
  } = useProfile();

  if (!canUserEditMarketingProfile(user!.userData.roles)) {
    return <Navigate to={`/marketing/profiles/${profileSlug}/view`} />;
  }

  if (status === 'error') {
    return (
      <Typography variant="h5" component="p" color="error">
        An error occurred trying to retrieve the profile
      </Typography>
    );
  }

  // TODO: behaviour when saving, i.e. status is pending but profile is truthy

  if (!profile) {
    return <Loader />;
  }

  return <EditProfileForm />;
};
