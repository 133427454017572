import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Button, Container } from '@mui/material';
import { View } from 'app/marketing/profiles/View';
import { useNavigate } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { canUserEditMarketingProfile } from 'utils/userRoles';

export const Preview = () => {
  const { user } = useAuth0<AuthUser>();
  const navigate = useNavigate();

  if (!canUserEditMarketingProfile(user!.userData.roles)) navigate('/marketing');

  return (
    <>
      {/* This container bounds the alert width to match the container inside View */}
      <Container sx={{ p: 0 }}>
        <Alert
          severity="info"
          action={
            <Button size="small" onClick={() => window.close()} color="inherit" variant="outlined" endIcon={<CloseIcon />}>
              Close preview
            </Button>
          }
          sx={{ mt: 1 }}
        >
          This is a preview of what your marketing profile will look like if published and viewed by an agent.
        </Alert>
      </Container>

      {/* View has its own container and renders the footer, so it must be outside the container here or can't consume full width.. */}
      <View ignorePublishedState />
    </>
  );
};
