import { Box, Paper, Typography, useTheme } from '@mui/material';
import { useWindowSize } from 'hooks/use-window-size';
import { useMemo, useState } from 'react';
import { ProfileData } from 'types/profile.interface';

import { useProfile } from '../../ProfileContext';
import { MapWithRadius } from './MapWithRadius';

export const LocationSection = () => {
  const {
    state: { profile },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const color = theme.palette.primary.main;

  const [mapContainerEl, setMapContainerEl] = useState<HTMLDivElement>();
  const size = useWindowSize();

  const [width, height] = useMemo(() => {
    if (!size) return [0, 0];
    const parentWidth = mapContainerEl?.clientWidth || 0;
    const parentHeight = mapContainerEl?.clientHeight || 0;
    return [parentWidth, parentHeight];
  }, [mapContainerEl?.clientWidth, mapContainerEl?.clientHeight, size]);

  return (
    <Paper sx={{ mb: '10px' }}>
      <Box sx={{ p: '20px' }}>
        <Typography variant="h5" color={color} mb={3}>
          Location
        </Typography>
        <Box ref={(el: any) => setMapContainerEl(el)} sx={{ minHeight: '450px', maxHeight: '600px' }}>
          <MapWithRadius center={profile.latLng} radiusKm={1} zoom={13} width={width} height={height} />
        </Box>
      </Box>
    </Paper>
  );
};
