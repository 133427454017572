import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React, { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { getConfig } from 'utils/config';

interface Auth0ProviderWithNavigateProps {
  children?: ReactElement;
}

export const Auth0ProviderWithNavigate = ({ children }: Auth0ProviderWithNavigateProps) => {
  const navigate = useNavigate();
  const { auth0Domain, auth0ClientId, auth0CallbackUrl, auth0Audience } = getConfig();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || '/marketing');
  };

  if (!(auth0Domain && auth0ClientId && auth0CallbackUrl && auth0Audience)) {
    return <></>;
  }

  return (
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        audience: auth0Audience,
        redirect_uri: auth0CallbackUrl,
        scope: 'openid profile email offline_access read:current_user',
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
