import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { AuthUser } from 'types/user.interface';
import { getConfig } from 'utils/config';
import { getUserId } from 'utils/user';

export const usePageTracking = () => {
  const location = useLocation();
  const [isInitialized, setIsInitialized] = useState(false);
  const { user } = useAuth0<AuthUser>();
  const { environment } = getConfig();
  const GATrackingID = 'UA-148325018-1';

  useEffect(() => {
    if (environment === 'local') {
      console.info(`Skipping ReactGA initialisation in local environment`);
      return;
    }

    if (user) {
      const userRoles = user.userData.roles;
      const userId = getUserId(user);
      ReactGA.initialize(GATrackingID, { gaOptions: { userId } });

      // 'dimension4' links to a custom dimension inside GA which stores the user role
      // 'dimension3' links to the custom dimension inside GA which stores the user ID
      // 'dimension6' links to the custom dimension inside GA which stores if the user is an enroller recruit agent
      const isEnrollerRecruitAgent = user.userData.contractedWithEnroller;
      ReactGA.set({ dimension4: userRoles[0], dimension3: userId, dimension6: isEnrollerRecruitAgent });
    } else {
      ReactGA.initialize(GATrackingID);
    }

    setIsInitialized(true);
  }, [environment, user]);

  useEffect(() => {
    if (isInitialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [isInitialized, location]);
};
