export interface AppConfig {
  environment: 'local' | 'development' | 'production';
  apiUrl: string;
  enrolmentsUrl: string;
  marketplaceUrl: string;
  cloudfrontUrl: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0CallbackUrl: string;
  auth0Audience: string;
  s3Endpoint: string;
  s3Bucket: string;
  sentryDsn: string;
  mapboxToken: string;
}

const throwIfUndefined = (envVar: string): string => {
  if (process.env[envVar] === undefined) {
    throw new Error(`${envVar} is not defined, cannot load application config`);
  }

  return process.env[envVar]!;
};

export const getConfig = (): AppConfig => {
  let env = throwIfUndefined('REACT_APP_ENV');
  if (env !== 'local' && env !== 'development' && env !== 'production') {
    env = 'production';
  }

  return {
    environment: env as 'local' | 'development' | 'production',
    apiUrl: throwIfUndefined('REACT_APP_BACKEND_HOST'),
    enrolmentsUrl: throwIfUndefined('REACT_APP_ENROLLER'),
    marketplaceUrl: throwIfUndefined('PUBLIC_URL'),
    cloudfrontUrl: 'https://marketplace-images.enroller.app',
    auth0Domain: throwIfUndefined('REACT_APP_AUTH0_DOMAIN'),
    auth0ClientId: throwIfUndefined('REACT_APP_AUTH0_CLIENT_ID'),
    auth0CallbackUrl: throwIfUndefined('REACT_APP_AUTH0_CALLBACK_URL'),
    auth0Audience: throwIfUndefined('REACT_APP_AUTH0_AUDIENCE'),
    s3Endpoint: throwIfUndefined('REACT_APP_S3_ENDPOINT'),
    s3Bucket: throwIfUndefined('REACT_APP_S3_BUCKET'),
    sentryDsn: throwIfUndefined('REACT_APP_SENTRY_DSN'),
    mapboxToken: throwIfUndefined('REACT_APP_MAPBOX_TOKEN'),
  };
};
